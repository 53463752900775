export const FormattedTime = ({ time }) => {
  const fTime = time.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
  return fTime;
};

export const TimeDifference = ({ startTime, endTime }) => {
  const diff = endTime - startTime;
  const diffHours = Math.floor(diff / 1000 / 60 / 60);
  const diffMinutes = Math.floor((diff / 1000 / 60 / 60 - diffHours) * 60);
  let result = "";
  if (diffHours > 0) {
    result += `${diffHours} hour`;
    if (diffHours > 1) {
      result += "s";
    }
    if (diffMinutes > 0) {
      result += " ";
    }
  }
  if (diffMinutes > 0) {
    result += `${diffMinutes} minutes`;
  }
  return result;
};
