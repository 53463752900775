/* eslint-disable react/prop-types */
import React, { useState } from "react";
import validator from "validator";
import { TextField, Box, Typography, LinearProgress } from "@mui/material";

export default function EnterPassword({
  password,
  setPassword,
  setValidPassword,
  includeProgress,
  includeConfirmPassword,
  noAutocomplete,
}) {
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState(null);
  const [passwordScore, setPasswordScore] = useState(0);

  const isValid = (score, realPassword, realConfirmPassword) => {
    if (
      score >= 74 &&
      (!includeConfirmPassword || realPassword === realConfirmPassword)
    ) {
      setValidPassword(true);
    } else {
      setValidPassword(false);
    }
  };

  const handlePasswordInput = (e) => {
    const value = e.target.value;
    setPassword(value);
    const score = Math.min(
      Math.round(validator.isStrongPassword(value, { returnScore: true })) * 2,
      100
    );
    setPasswordScore(score);
    if (value.length < 8) {
      setPasswordError("Your password must be at least 8 characters");
    } else if (value.search(/[a-z]/i) < 0) {
      setPasswordError("Your password must contain at least one letter");
    } else if (value.search(/[0-9]/) < 0) {
      setPasswordError("Your password must contain at least one digit");
    } else {
      setPasswordError(null);
    }
    isValid(score, value, confirmPassword);
  };

  const handleConfirmPasswordInput = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    if (password !== value) {
      setConfirmPasswordError("Your passwords must match!");
    } else {
      setConfirmPasswordError(null);
    }
    isValid(passwordScore, password, value);
  };

  return (
    <>
      <TextField
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete={!noAutocomplete ? "password" : "new-password"}
        onChange={(e) => handlePasswordInput(e)}
        value={password ? password : ""}
        error={!!passwordError}
        helperText={passwordError}
      />
      {includeConfirmPassword && (
        <TextField
          required
          fullWidth
          name="confirmPassword"
          label="Confirm Password"
          type="password"
          id="confirmPassword"
          onChange={(e) => handleConfirmPasswordInput(e)}
          value={confirmPassword ? confirmPassword : ""}
          error={!!confirmPasswordError}
          helperText={confirmPasswordError}
          sx={{ mt: 2 }}
        />
      )}
      {includeProgress && (
        <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
          <Box sx={{ minWidth: 65 }}>
            <Typography variant="body2" color="text.secondary">
              Strength:
            </Typography>
          </Box>
          <Box sx={{ width: "100%", mr: 1 }}>
            <LinearProgress
              variant="determinate"
              value={passwordScore}
              color={
                passwordScore < 74
                  ? "error"
                  : passwordScore < 80
                  ? "warning"
                  : "success"
              }
            />
          </Box>
        </Box>
      )}
    </>
  );
}
