/* eslint-disable react/prop-types */
import React, { useEffect } from "react";

import Backdrop from "../components/Backdrop/Backdrop";
import Spinner from "../components/Spinner/Spinner";
import Map from "../components/Map/Map.js";
import DiffMap from "../components/Map/DiffMap.js";
import { ViewStation } from "../components/Stations/ViewStation";
import { BookCharger } from "../components/Stations/BookCharger";
import { CreateEditStation } from "../components/Stations/CreateEditStation";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import EvStationIcon from "@mui/icons-material/EvStation";
import HubIcon from "@mui/icons-material/Hub";
import ElectricCarIcon from "@mui/icons-material/ElectricCar";
import ToggleMap from "../components/Stations/Utils/ToggleMap";
import handleErrors from "../utils/handleErrors";

import { config } from "../Constants";
const API_URL = config.api;

const actions = [
  { icon: <EvStationIcon />, name: "Add Station", path: "createStation" },
  { icon: <HubIcon />, name: "Add Network", path: "/networks" },
  { icon: <ElectricCarIcon />, name: "Add Car", path: "/cars" },
];

const ChargersPage = ({ user }) => {
  const [creating, setCreating] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [selectedStation, setSelectedStation] = React.useState(null);
  const [selectedCharger, setSelectedCharger] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [publicStations, setPublicStations] = React.useState([]);
  const [privateStations, setPrivateStations] = React.useState([]);
  const [latLng, setLatLng] = React.useState(null);
  const [booking, setBooking] = React.useState(false);
  const [viewStation, setViewStation] = React.useState(false);
  const [showAll, setShowAll] = React.useState(true);
  const [zoomLevel, setZoomLevel] = React.useState(14);
  const [center, setCenter] = React.useState({
    lat: 51.898,
    lng: -8.475,
  });

  useEffect(() => {
    fetchPublicStations();
    if (user) fetchPrivateStations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const fetchPublicStations = () => {
    const requestBody = {
      query: `
        query {
          publicStations {
            _id
            coordinates
            tomTomId
          }
        }
      `,
    };

    fetch(API_URL, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return handleErrors(res);
      })
      .then((resData) => {
        setPublicStations(resData.data.publicStations);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchPrivateStations = () => {
    const requestBody = {
      query: `
        query {
          privateStations {
            _id
            coordinates
            
          }
        }
      `,
    };

    user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
        },
      })
        .then((res) => {
          return handleErrors(res);
        })
        .then((resData) => {
          setPrivateStations(resData.data.privateStations);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const handleZoomChanged = (newZoom) => {
    if (newZoom !== zoomLevel) {
      setZoomLevel(newZoom);
    }
  };

  const handleCenterChanged = (newCenter) => {
    if (newCenter !== center) {
      setCenter(newCenter);
    }
  };

  const startCreateStationHandler = () => {
    setLatLng(null);
    setCreating(true);
  };

  const modalCancelHandler = () => {
    setCreating(false);
    setEditing(false);
    setSelectedStation(null);
    setSelectedCharger(null);
    setBooking(false);
    setViewStation(false);
  };

  const showDetailHandler = (stationId) => {
    const requestBody = {
      query: `
          query Station($stationId: ID!) {
            station(stationId: $stationId) {
              _id
              name
              address
              coordinates
              network {
                _id
                name
              }
              creator {
                _id
                firebaseId
              }
            }
          }
        `,
      variables: {
        stationId: stationId,
      },
    };

    if (user) {
      user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
        fetch(API_URL, {
          method: "POST",
          body: JSON.stringify(requestBody),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + idToken,
          },
        })
          .then((res) => {
            return handleErrors(res);
          })
          .then((resData) => {
            const selectedStation = resData.data.station;
            setSelectedStation(selectedStation);
            setLatLng({
              lat: selectedStation.coordinates[0],
              lng: selectedStation.coordinates[1],
            });
            setViewStation(true);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    } else {
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          return handleErrors(res);
        })
        .then((resData) => {
          const selectedStation = resData.data.station;
          setSelectedStation(selectedStation);
          setLatLng({
            lat: selectedStation.coordinates[0],
            lng: selectedStation.coordinates[1],
          });
          setViewStation(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleEdit = () => {
    setEditing(!editing);
  };

  const toggleMap = () => {
    setShowAll(!showAll);
  };

  return (
    <React.Fragment>
      {(creating || selectedStation) && <Backdrop />}
      {user && privateStations?.length > 0 && (
        <ToggleMap onToggle={toggleMap} />
      )}
      {(creating || editing) && (
        <CreateEditStation
          latLng={latLng}
          setLatLng={setLatLng}
          creating={creating}
          privateStations={privateStations}
          setPrivateStations={setPrivateStations}
          selectedStation={selectedStation}
          setSelectedStation={setSelectedStation}
          setEditing={setEditing}
          handleEdit={handleEdit}
          setCreating={setCreating}
          setLoading={setIsLoading}
          modalCancelHandler={modalCancelHandler}
          user={user}
        />
      )}
      {selectedStation && !editing && !booking && (
        <ViewStation
          viewStation={viewStation}
          setBooking={setBooking}
          setSelectedCharger={setSelectedCharger}
          latLng={latLng}
          selectedStation={selectedStation}
          modalCancelHandler={modalCancelHandler}
          handleEdit={handleEdit}
          user={user}
        />
      )}
      {booking && (
        <BookCharger
          booking={booking}
          setBooking={setBooking}
          selectedCharger={selectedCharger}
          setSelectedCharger={setSelectedCharger}
          selectedStation={selectedStation}
          setSelectedStation={setSelectedStation}
          modalCancelHandler={modalCancelHandler}
          user={user}
        />
      )}
      {user && (
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{ position: "absolute", bottom: 20, right: 20, zIndex: 1 }}
          icon={<SpeedDialIcon />}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={
                action.path === "createStation"
                  ? startCreateStationHandler
                  : null
              }
              href={action.path !== "createStation" ? action.path : ""}
            />
          ))}
        </SpeedDial>
      )}
      {isLoading && <Spinner />}
      {showAll ? (
        <Map
          enableClustering
          publicStations={publicStations}
          privateStations={user ? privateStations : []}
          authUserId={user}
          onViewDetail={showDetailHandler}
          zoom={zoomLevel}
          handleZoomChanged={handleZoomChanged}
          center={center}
          handleCenterChanged={handleCenterChanged}
          noStations={false}
        />
      ) : (
        <DiffMap
          enableClustering
          stations={privateStations}
          authUserId={user.uid}
          onViewDetail={showDetailHandler}
          zoom={zoomLevel}
          handleZoomChanged={handleZoomChanged}
          center={center}
          handleCenterChanged={handleCenterChanged}
        />
      )}
    </React.Fragment>
  );
};

export default ChargersPage;
