/* eslint-disable react/prop-types */
import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {
  TextField,
  OutlinedInput,
  InputAdornment,
  Typography,
} from "@mui/material";
import { chargerTypes } from "../Utils/ChargerInfo";

export const Chargers = ({ chargers, setChargers }) => {
  const addCharger = () => {
    setChargers([
      ...chargers,
      { name: "", type: "type_2", price: 0, power: 0 },
    ]);
  };

  const removeCharger = (index) => {
    const newChargers = [...chargers];
    newChargers.splice(index, 1);
    setChargers(newChargers);
  };

  return (
    <Box>
      <Grid container spacing={5}>
        {chargers.map((charger, index) => {
          return (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-label">
                  Label (Optional)
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-label"
                  label="Label"
                  type="text"
                  value={charger.name}
                  onChange={(e) => {
                    const updatedChargers = [...chargers];
                    updatedChargers[index].name = e.target.value;
                    setChargers(updatedChargers);
                  }}
                />
              </FormControl>
              <FormControl fullWidth sx={{ m: 1 }}>
                <TextField
                  select
                  label="Charger Type"
                  defaultValue={charger.type ? charger.type : "type_2"}
                  onChange={(e) => {
                    const updatedChargers = [...chargers];
                    updatedChargers[index].type = e.target.value;
                    setChargers(updatedChargers);
                  }}
                >
                  {chargerTypes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <Box
                        sx={{
                          display: "flex",
                          verticalAlign: "middle",
                        }}
                      >
                        {option.imgSrc && (
                          <img
                            src={option.imgSrc}
                            style={{
                              width: "40px",
                              height: "40px",
                              verticalAlign: "middle",
                            }}
                            alt="connector_symbol"
                          />
                        )}
                        <Typography variant="p" mt={1} ml={1}>
                          {" "}
                          {option.label}{" "}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>

              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-power">
                  Power
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-power"
                  endAdornment={
                    <InputAdornment position="end">kW</InputAdornment>
                  }
                  label="Power"
                  type="number"
                  min="0"
                  step="0.01"
                  value={charger.power}
                  onChange={(e) => {
                    const updatedChargers = [...chargers];
                    updatedChargers[index].power = e.target.value;
                    setChargers(updatedChargers);
                  }}
                />
              </FormControl>
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-price">
                  Price per hour
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-price"
                  startAdornment={
                    <InputAdornment position="start">€</InputAdornment>
                  }
                  label="Price"
                  type={charger.price === 0 ? "text" : "number"}
                  inputProps={{
                    min: 0,
                    step: "0.01",
                  }}
                  value={charger.price === 0 ? "Free" : charger.price}
                  onChange={(e) => {
                    const updatedChargers = [...chargers];
                    updatedChargers[index].price = e.target.value;
                    setChargers(updatedChargers);
                  }}
                />
              </FormControl>
              <Button
                sx={{ float: "right" }}
                color="error"
                variant="outlined"
                onClick={() => removeCharger(index)}
              >
                Remove
              </Button>
            </Grid>
          );
        })}
      </Grid>
      <Button variant={"contained"} onClick={addCharger} sx={{ marginTop: 5 }}>
        + Add a Charger
      </Button>
    </Box>
  );
};
