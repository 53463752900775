/* eslint-disable react/prop-types */
import React from "react";
import {
  Box,
  Button,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  TextField,
  Grid,
  Divider,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { config } from "../../../Constants";
import handleErrors from "../../../utils/handleErrors";
const API_URL = config.api;

export function NetworkDetails({
  selectedNetwork,
  user,
  email,
  setEmail,
  code,
  setCode,
}) {
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const email = data.get("email");
    const requestBody = {
      query: `
          mutation GenerateInvite($id: ID! $email: String!) {
            generateInvite(networkId: $id, email: $email)
          }
        `,
      variables: {
        id: selectedNetwork.network._id,
        email: email,
      },
    };
    user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
        },
      })
        .then((res) => {
          return handleErrors(res);
        })
        .then((resData) => {
          setCode(resData.data.generateInvite);
          setEmail(email);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} sm={5}>
        <List>
          <ListSubheader>Details</ListSubheader>
          <ListItem>
            <ListItemText primary={`Name: ${selectedNetwork.network.name}`} />
          </ListItem>
          <Divider component="li" />
          <ListItem>
            <ListItemText
              sx={{ textTransform: "capitalize" }}
              primary={`Privacy: ${selectedNetwork.network.privacy}`}
            />
          </ListItem>
          <Divider component="li" />
          <ListItem>
            <ListItemText
              primary={`Members: ${selectedNetwork.network.numMembers}`}
            />
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12} sm={6}>
        {(selectedNetwork.network.privacy === "public" ||
          selectedNetwork.network.creator.firebaseId === user.uid) && (
          <>
            {selectedNetwork.network.privacy === "public" ? (
              <>
                <h3>Invite Code</h3>
                <TextField
                  variant="outlined"
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Copy to clipboard">
                          <IconButton
                            onClick={() => {
                              navigator.clipboard.writeText(
                                selectedNetwork.network.invite.code
                              );
                            }}
                          >
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  value={selectedNetwork.network.invite.code}
                />
              </>
            ) : (
              <Box>
                <Box component="form" onSubmit={handleSubmit} noValidate mt={1}>
                  <b>Invite Member</b>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="email"
                    helperText="Enter the email address of the user you want to invite"
                    label="Email Address"
                    name="email"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Copy to clipboard">
                            <Button
                              type="submit"
                              variant="contained"
                              startIcon={<PersonAddIcon />}
                            >
                              Invite
                            </Button>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                {code && (
                  <Box>
                    <p>
                      Invite Code for <b>{email}</b>:
                    </p>
                    <TextField
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="Copy to clipboard">
                              <IconButton
                                onClick={() => {
                                  navigator.clipboard.writeText(code);
                                }}
                              >
                                <ContentCopyIcon />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                      value={code}
                    />
                  </Box>
                )}
              </Box>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}
