/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import Divider from "@mui/material/Divider";
import { ListSubheader, Tooltip } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { Policies } from "./Panels/Policies";
import { NetworkDetails } from "./Panels/NetworkDetails";
import handleErrors from "../../utils/handleErrors";

import { config } from "../../Constants";
const API_URL = config.api;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ width: "100%" }}
      {...other}
    >
      {value === index && <Box sx={{ p: 3, pt: 0 }}>{children}</Box>}
    </div>
  );
}

export const ViewNetwork = ({
  viewNetwork,
  networks,
  setNetworks,
  selectedNetwork,
  user,
  setEditing,
  modalCancelHandler,
}) => {
  const [members, setMembers] = useState(false);
  const [tab, setTab] = useState(0);
  const [code, setCode] = useState(null);
  const [email, setEmail] = useState(null);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (selectedNetwork.network.creator.firebaseId === user.uid) {
      const requestBody = {
        query: `
              query NetworkMembers($networkId: ID!){
                networkMembers(networkId: $networkId) {
                  _id
                  firstName
                  lastName
                  email
                  phoneNumber
                }
              }
            `,
        variables: {
          networkId: selectedNetwork.network._id,
        },
      };

      user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
        fetch(API_URL, {
          method: "POST",
          body: JSON.stringify(requestBody),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + idToken,
          },
        })
          .then((res) => {
            return handleErrors(res);
          })
          .then((resData) => {
            setMembers(resData.data.networkMembers);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user,
    selectedNetwork.network._id,
    selectedNetwork.network.creator.firebaseId,
  ]);

  const modalDeleteHandler = () => {
    const requestBody = {
      query: `
          mutation DeleteNetwork($id: ID!) {
            deleteNetwork(networkId: $id) {
            _id
            }
          }
        `,
      variables: {
        id: selectedNetwork.network._id,
      },
    };
    user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
        },
      })
        .then((res) => {
          return handleErrors(res);
        })
        .then(() => {
          const updatedNetworks = networks.filter((network) => {
            return network.network._id !== selectedNetwork.network._id;
          });
          setNetworks(updatedNetworks);
          modalCancelHandler();
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const modalLeaveHandler = () => {
    const requestBody = {
      query: `
          mutation LeaveNetwork($id: ID!) {
            leaveNetwork(networkId: $id) {
            _id
            }
          }
        `,
      variables: {
        id: selectedNetwork.network._id,
      },
    };
    user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
        },
      })
        .then((res) => {
          return handleErrors(res);
        })
        .then((resData) => {
          const updatedNetworks = networks.filter((network) => {
            return network.network._id !== selectedNetwork.network._id;
          });
          setNetworks(updatedNetworks);
          modalCancelHandler();
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Dialog
      open={viewNetwork}
      fullScreen={fullScreen}
      fullWidth
      onClose={modalCancelHandler}
      maxWidth={"lg"}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {!fullScreen && <>{selectedNetwork.network.name}</>}
        <IconButton
          aria-label="close"
          onClick={modalCancelHandler}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={!fullScreen}>
        <Box
          sx={{
            flexGrow: { xs: 0, md: 1 },
            bgcolor: "background.paper",
            display: { xs: "block", md: "flex" },
            width: "100%",
          }}
        >
          <Box
            sx={{
              borderBottom: { xs: 1, md: 0 },
              borderColor: "divider",
            }}
          >
            <Tabs
              orientation={fullScreen ? "horizontal" : "vertical"}
              variant={fullScreen ? "fullWidth" : "scrollable"}
              value={tab}
              onChange={handleTabChange}
              aria-label="Vertical tabs example"
              sx={{
                borderRight: { xs: 0, md: 1 },
                borderColor: "divider",
                height: { xs: "auto", md: "100%" },
              }}
            >
              <Tab
                label="Info"
                id="simple-tab-0"
                aria-controls="simple-tabpanel-0"
                sx={{ textAlign: "left" }}
              />
              ,
              <Tab
                label="Policies"
                id="simple-tab-1"
                aria-controls="simple-tabpanel-1"
              />
              ,
              {selectedNetwork.network.creator &&
                user.uid === selectedNetwork.network.creator.firebaseId && (
                  <Tab
                    label="Members"
                    id="simple-tab-2"
                    aria-controls="simple-tabpanel-2"
                  />
                )}
            </Tabs>
          </Box>
          <TabPanel value={tab} index={0}>
            <NetworkDetails
              selectedNetwork={selectedNetwork}
              user={user}
              email={email}
              setEmail={setEmail}
              code={code}
              setCode={setCode}
            />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Policies
              networks={networks}
              setNetworks={setNetworks}
              selectedNetwork={selectedNetwork}
              user={user}
            />
          </TabPanel>
          <TabPanel value={tab} index={2} sx={{ width: "100%" }}>
            <List
              sx={{
                width: "100%",
                maxWidth: 400,
                bgcolor: "background.paper",
                overflow: "auto",
              }}
              subheader={<ListSubheader>Members</ListSubheader>}
            >
              {members &&
                members.map((member, index) => {
                  return (
                    <Box key={index}>
                      <ListItem
                        key={index}
                        secondaryAction={
                          <>
                            <Tooltip title="Phone">
                              <IconButton
                                edge="end"
                                aria-label="call"
                                sx={{ marginRight: "1px" }}
                              >
                                <CallIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Email">
                              <IconButton
                                edge="end"
                                aria-label="email"
                                sx={{ marginRight: "1px" }}
                              >
                                <EmailIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Remove">
                              <IconButton edge="end" aria-label="delete">
                                <PersonRemoveIcon />
                              </IconButton>
                            </Tooltip>
                          </>
                        }
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              backgroundColor: `#${Math.floor(
                                Math.random() * 0xffffff
                              ).toString(16)}`,
                            }}
                          >
                            <AccountCircleIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={`${member.firstName} ${member.lastName}`}
                          secondary={member.email}
                        />
                      </ListItem>
                      {index !== members.length - 1 && (
                        <Divider variant="inset" component="li" />
                      )}
                    </Box>
                  );
                })}
            </List>
          </TabPanel>
        </Box>
      </DialogContent>
      <DialogActions>
        {selectedNetwork.network.creator &&
        user.uid === selectedNetwork.network.creator.firebaseId ? (
          <Box>
            <Button color="error" onClick={modalDeleteHandler}>
              Delete Network
            </Button>
            <Button onClick={() => setEditing(true)}>Edit Network</Button>
          </Box>
        ) : (
          <Button color="error" onClick={modalLeaveHandler}>
            Leave Network
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
