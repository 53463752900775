/* eslint-disable react/prop-types */

import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Map from "../Map/Map";
import { convertChargerTypes } from "./Utils/ChargerInfo";

import handleErrors from "../../utils/handleErrors";
import { config } from "../../Constants";
import { Alert } from "@mui/material";
const API_URL = config.api;

export const ViewStation = ({
  viewStation,
  setBooking,
  setSelectedCharger,
  latLng,
  selectedStation,
  selectedCharger,
  user,
  modalCancelHandler,
  handleEdit,
}) => {
  const [chargers, setChargers] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    if (user) fetchChargers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const fetchChargers = () => {
    const requestBody = {
      query: `
        query GetChargers($stationId: ID!) {
          getChargers(stationId: $stationId) {
            _id
            name
            type
            power
            price
            tomTom
          }
        }
      `,
      variables: {
        stationId: selectedStation._id,
      },
    };

    user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
        },
      })
        .then((res) => {
          return handleErrors(res);
        })
        .then((resData) => {
          const updatedChargers = [];
          // eslint-disable-next-line array-callback-return
          resData.data.getChargers.map((charger) => {
            const chargerInfo = convertChargerTypes(charger.type);
            updatedChargers.push({
              ...charger,
              label: chargerInfo.label,
              imgSrc: chargerInfo.imgSrc,
            });
          });
          selectedStation.chargers = updatedChargers;
          setChargers(updatedChargers);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const handleBook = (charger) => () => {
    setBooking(true);
    setSelectedCharger(charger);
  };

  return (
    <Dialog
      open={viewStation}
      fullScreen={fullScreen}
      fullWidth
      onClose={modalCancelHandler}
      maxWidth={"xl"}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        Station Details
        <IconButton
          aria-label="close"
          onClick={modalCancelHandler}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container columnSpacing={1} rowSpacing={1}>
          <Grid item xs={12} lg={6}>
            <h1>{selectedStation.name}</h1>
            <p>{selectedStation.address}</p>
            {selectedStation.description && (
              <p>Provider: {selectedStation.description}</p>
            )}
            {selectedStation.network && (
              <h4>Network: {selectedStation.network.name}</h4>
            )}

            {selectedStation.tomTomId && (
              <Alert severity="warning">
                Public Charger - Bookings Unavailable
              </Alert>
            )}
            {chargers && chargers.length > 0 && (
              <Grid container sx={{ maxHeight: "500px", overflow: "auto" }}>
                {chargers.map((charger) => (
                  <Grid item key={charger._id} xs={12} sm={6} p={1}>
                    <Grid
                      container
                      sx={{
                        border: `1px solid ${
                          selectedCharger && selectedCharger._id === charger._id
                            ? "red"
                            : "#ccc"
                        }`,
                        borderRadius: "5px",
                        overflow: "hidden",
                      }}
                    >
                      {charger.imgSrc && (
                        <Grid item xs={4} sx={{ margin: "auto" }} p={1}>
                          <img
                            src={charger.imgSrc}
                            style={{
                              width: "100%",
                              maxWidth: "80px",
                              minWidth: "40px",
                            }}
                            alt="connector_symbol"
                          />
                        </Grid>
                      )}
                      <Grid item xs>
                        {charger.name && (
                          <p>
                            <i>{charger.name.slice(0, 18)}</i>
                          </p>
                        )}
                        <p>{charger.label.slice(0, 18)}</p>
                        <p>{charger.power}KW</p>
                      </Grid>
                      {!charger.tomTom ? (
                        <Grid item xs={4} p={1}>
                          {charger.price === 0 ? (
                            <p>
                              <b>Free</b>
                            </p>
                          ) : (
                            <p>{charger.price}&euro;/hr</p>
                          )}
                          {setBooking && (
                            <Button
                              onClick={handleBook(charger)}
                              variant="outlined"
                            >
                              Book
                            </Button>
                          )}
                        </Grid>
                      ) : (
                        <Grid item xs={3} />
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            <Map
              style={{
                width: "100%",
                height: "100%",
                minHeight: "400px",
              }}
              LatLng={latLng}
              zoom={16}
              noStations={true}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {selectedStation.creator &&
          user.uid === selectedStation.creator.firebaseId &&
          handleEdit && (
            <Button autoFocus onClick={handleEdit}>
              Edit Station
            </Button>
          )}
        <Button onClick={modalCancelHandler}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
