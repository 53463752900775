/* eslint-disable react/prop-types */
import React, { createRef, useEffect } from "react";

import Backdrop from "../components/Backdrop/Backdrop";
import Spinner from "../components/Spinner/Spinner";
import CarList from "../components/Cars/CarList";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogContentText,
  DialogActions,
  IconButton,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import handleErrors from "../utils/handleErrors";

import { getAuth } from "firebase/auth";

import { config } from "../Constants";
const API_URL = config.api;

const CarsPage = ({ user }) => {
  const [creating, setCreating] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [cars, setCars] = React.useState([]);
  const [selectedCar, setSelectedCar] = React.useState(null);
  const nameElRef = createRef();
  const regElRef = createRef();

  getAuth();

  useEffect(() => {
    if (user) fetchCars();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const modalConfirmHandler = () => {
    setCreating(false);
    const name = nameElRef.current.value;
    const reg = regElRef.current.value;

    if (name.trim().length === 0 || reg.trim().length === 0) {
      return;
    }

    const requestBody = {
      query: `
        mutation CreateCar($name: String!, $reg: String!) {
          createCar(carInput: {name: $name, reg: $reg}) {
            _id
            name
            reg
          }
        }
      `,
      variables: {
        name: name,
        reg: reg,
      },
    };

    user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
        },
      })
        .then((res) => {
          return handleErrors(res);
        })
        .then((resData) => {
          const updatedCars = [...cars];
          updatedCars.push({
            _id: resData.data.createCar._id,
            name: resData.data.createCar.name,
            reg: resData.data.createCar.reg,
            owner: {
              _id: user.uid,
            },
          });
          setCars(updatedCars);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const modalCancelHandler = () => {
    setCreating(false);
    setSelectedCar(null);
  };

  const fetchCars = () => {
    setIsLoading(true);
    const requestBody = {
      query: `
          query {
            cars {
              _id
              name
              reg
              owner {
                _id
                email
              }
            }
          }
        `,
    };

    user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
        },
      })
        .then((res) => {
          return handleErrors(res);
        })
        .then((resData) => {
          const cars = resData.data.cars;
          setCars(cars);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    });
  };

  const deleteCarHandler = (carId) => {
    setIsLoading(true);
    const requestBody = {
      query: `
          mutation DeleteCar($id: ID!) {
            deleteCar(carId: $id) {
            _id
            }
          }
        `,
      variables: {
        id: carId,
      },
    };

    user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
        },
      })
        .then((res) => {
          return handleErrors(res);
        })
        .then((resData) => {
          const updatedCars = cars.filter((car) => {
            return car._id !== carId;
          });
          setCars(updatedCars);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    });
  };

  return (
    <React.Fragment>
      {(creating || selectedCar) && <Backdrop />}
      {creating && (
        <Dialog open={creating}>
          <DialogTitle sx={{ m: 0, p: 2 }}>
            Add Car
            <IconButton
              aria-label="close"
              onClick={modalCancelHandler}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              To add a car, please enter the name and registration number.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="text"
              fullWidth
              inputRef={nameElRef}
              placeholder="e.g. ID3"
            />
            <TextField
              autoFocus
              margin="dense"
              id="reg"
              label="Registration"
              type="text"
              fullWidth
              inputRef={regElRef}
              placeholder="e.g. 231-C-1234"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={modalCancelHandler} color="primary">
              Cancel
            </Button>
            <Button onClick={modalConfirmHandler} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Box maxWidth={600} margin={"auto"}>
        <Typography variant="h4" component="h1" gutterBottom pt={10} ml={2}>
          Cars
        </Typography>
        {isLoading ? (
          <Spinner />
        ) : cars.length > 0 ? (
          <Box mt={1} ml={2} mr={2}>
            <CarList cars={cars} onDelete={deleteCarHandler} />
          </Box>
        ) : (
          <Typography
            color="error"
            variant="h6"
            component="h2"
            gutterBottom
            align="center"
            mt={1}
            ml={2}
            mr={2}
          >
            No cars registered yet
          </Typography>
        )}
        <Button
          variant="contained"
          onClick={() => setCreating(true)}
          startIcon={<AddIcon />}
          sx={{ mt: 1, mb: 2, mr: 2, float: "right" }}
        >
          Add Car
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default CarsPage;
