import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Button, Box, Typography, Alert } from "@mui/material";
import {
  getAuth,
  signInWithEmailAndPassword,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from "firebase/auth";

import Spinner from "../../Spinner/Spinner";
import EnterPassword from "../utils/EnterPassword";

// eslint-disable-next-line react/prop-types
export default function ResetPassword({ actionCode }) {
  const { enqueueSnackbar } = useSnackbar();
  const auth = getAuth();
  const [error, setError] = React.useState(null);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [validPassword, setValidPassword] = React.useState(false);
  const [updated, setUpdated] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(null);
  };

  const handlePasswordReset = () => {
    if (!validPassword) {
      setError("Passwords do not match");
      return;
    }
    confirmPasswordReset(auth, actionCode, password)
      .then(() => {
        enqueueSnackbar("Your password has been updated!", {
          variant: "success",
        });
        signInWithEmailAndPassword(auth, email, password)
          .then(() => {
            enqueueSnackbar("Signed in successfully", { variant: "success" });
            setUpdated(true);
          })
          .catch((error) => {
            setError("Couldn't sign in");
            console.log(error);
          });
      })
      .catch((error) => {
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
        setError("Couldn't confirm password reset");
        console.log(error);
      });
  };

  useEffect(() => {
    verifyPasswordResetCode(auth, actionCode)
      .then((email) => {
        setEmail(email);
      })
      .catch((error) => {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
        setError("Couldn't verify password reset code");
        console.log(error);
      });
  }, [auth, actionCode]);

  return updated ? (
    <Navigate replace to="/" />
  ) : (
    <>
      <Typography component="h1" variant="h5">
        Reset Password
      </Typography>
      {email ? (
        <Box mb={2}>
          <Box sx={{ textAlign: "center", mt: 2 }}>
            <Typography
              variant="body1"
              gutterBottom
              component="p"
              mt={5}
              sx={{ display: "inline" }}
            >
              Enter a new password for:&nbsp;
              <Box component="span" fontWeight="fontWeightMedium">
                {email}
              </Box>
            </Typography>
          </Box>

          {error && (
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%", mt: 2 }}
            >
              {error}
            </Alert>
          )}
          <Box sx={{ mt: 2 }}>
            <EnterPassword
              password={password}
              setPassword={setPassword}
              setValidPassword={setValidPassword}
              includeProgress
              includeConfirmPassword
              noAutocomplete
            />
          </Box>
          <Button
            disabled={!validPassword}
            variant="contained"
            fullWidth
            onClick={handlePasswordReset}
            sx={{ mt: 2 }}
          >
            Reset Password
          </Button>
        </Box>
      ) : error ? (
        <Alert
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%", mt: 2 }}
        >
          {error}
        </Alert>
      ) : (
        <Spinner />
      )}
    </>
  );
}
