import React from "react";

import { ListItem, ListItemText, Grid } from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import Button from "@mui/material/Button";

const bookingList = ({ bookings, setSelectedCharger, onDelete }) =>
  bookings.map((booking) => {
    return (
      <ListItem key={booking._id}>
        <Grid container>
          <Grid item xs={4} sm={3} md={2}>
            <Timeline sx={{ paddingLeft: 0, marginLeft: 0 }}>
              <TimelineItem>
                <TimelineOppositeContent color="textSecondary">
                  {new Date(booking.bookingStart).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent color="textSecondary">
                  {new Date(booking.bookingEnd).toLocaleTimeString([], {
                    hour: "numeric",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                </TimelineSeparator>
              </TimelineItem>
            </Timeline>
          </Grid>
          <Grid item xs={8} sm={6} md={7}>
            {booking.charger.name ? (
              <ListItemText
                secondary={`Address: ${booking.charger.station.address}`}
                sx={{ paddingTop: "10px" }}
              >
                Label: <b>{booking.charger.name}</b>
                <br />
                Station: <b>{booking.charger.station.name}</b>
              </ListItemText>
            ) : (
              <ListItemText
                secondary={`Address: ${booking.charger.station.address}`}
                sx={{ paddingTop: "10px" }}
              >
                Station: <b>{booking.charger.station.name}</b>
              </ListItemText>
            )}
            <ListItemText
              sx={{ paddingTop: "10px" }}
              secondary={`Car: ${booking.car.name}`}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={3}
            md={3}
            columnSpacing={2}
            sx={{ marginTop: { xs: 0, sm: 0, md: 2 } }}
          >
            <Grid item xs={6} sm={12} md={6} sx={{ height: "5px" }}>
              <Button
                variant="contained"
                // eslint-disable-next-line no-invalid-this
                onClick={() => setSelectedCharger(booking.charger)}
                sx={{ width: "100%" }}
              >
                View
              </Button>
            </Grid>
            {onDelete ? (
              <Grid item xs={6} sm={12} md={6}>
                <Button
                  variant="contained"
                  // eslint-disable-next-line no-invalid-this
                  onClick={onDelete.bind(this, booking._id)}
                  sx={{ width: "100%" }}
                >
                  Cancel
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </ListItem>
    );
  });

export default bookingList;
