import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Typography, Alert, Button } from "@mui/material";
import { getAuth, applyActionCode, sendEmailVerification } from "firebase/auth";
import Spinner from "../../Spinner/Spinner";
import { useSnackbar } from "notistack";

// eslint-disable-next-line react/prop-types
export default function VerifyEmail({ actionCode }) {
  const { enqueueSnackbar } = useSnackbar();
  const auth = getAuth();
  const [error, setError] = React.useState(null);
  const [verified, setVerified] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(null);
  };

  const handleVerifyEmail = () => {
    sendEmailVerification(auth.currentUser).then(() => {
      enqueueSnackbar("Email verification sent!", { variant: "info" });
    });
  };

  useEffect(() => {
    applyActionCode(auth, actionCode)
      .then(() => {
        // Email address has been verified.
        enqueueSnackbar("Email Address Verified!", { variant: "success" });
        setVerified(true);
      })
      .catch((error) => {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
        setError("Code is invalid or expired. Please try again.");
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, actionCode]);

  return (
    <>
      <Typography component="h1" variant="h5">
        Verify Email Address
      </Typography>

      {verified ? (
        <Navigate replace to="/" />
      ) : error ? (
        <>
          <Alert
            onClose={handleClose}
            severity="error"
            sx={{ width: "100%", mt: 3, mb: 2 }}
          >
            {error}
          </Alert>
          <Button variant="contained" onClick={handleVerifyEmail}>
            Resend Verification Email
          </Button>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
}
