/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  useJsApiLoader,
} from "@react-google-maps/api";
import Spinner from "../Spinner/Spinner";
import Backdrop from "../Backdrop/Backdrop";
import styles from "./map.module.css";
import AutocompletePlace from "./AutocompletePlace";
import { Box } from "@mui/material";

const containerStyle = {
  width: "100%",
  height: "100vh",
};

export default function Map({
  autocomplete,
  enableClustering,
  publicStations,
  privateStations,
  style,
  handleClick,
  LatLng,
  setLatLng,
  onViewDetail,
  zoom,
  handleZoomChanged,
  center,
  handleCenterChanged,
  name,
  setName,
  address,
  setAddress,
  noStations,
}) {
  const [libraries] = React.useState(["places"]);
  const [mapCenter, setMapCenter] = React.useState(
    LatLng
      ? LatLng
      : center
      ? center
      : {
          lat: 51.898,
          lng: -8.475,
        }
  );
  const [mapZoom, setMapZoom] = React.useState(zoom ? zoom : 14);
  const [map, setMap] = React.useState(null);
  const [stations, setStations] = React.useState(null);

  useEffect(() => {
    if (publicStations?.length > 0 && privateStations?.length > 0) {
      setStations(publicStations.concat(privateStations));
    } else if (publicStations?.length > 0) {
      setStations(publicStations);
    } else if (privateStations?.length > 0) {
      setStations(privateStations);
    }
  }, [publicStations, privateStations]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyA1xBYl1u-IP8vjXUXReJ1zHEB3sGQQMRw",
    libraries,
  });

  const setMapLatLng = (lat, lng) => {
    setMapZoom(18);
    setMapCenter({ lat, lng });
    setLatLng({ lat, lng });
  };

  // Marker & Cluster Icons
  const publicIcon = "/images/markers/public_icon.png";
  const privateIcon = "/images/markers/private_icon.png";
  const clusterOptions = {
    imagePath: "/images/clusterer/m",
  };

  return isLoaded ? (
    <>
      {autocomplete && (
        <>
          <AutocompletePlace
            setName={setName}
            setAddress={setAddress}
            setLatLng={setMapLatLng}
          />
          {name ? (
            <>
              <h3>Location Details</h3>
              <div className={styles.searchResult}>
                <h3>{name}</h3>
                <p>{address}</p>
              </div>
              <p>
                <i>Move the Marker to the stations exact location</i>
              </p>
            </>
          ) : (
            <br></br>
          )}
        </>
      )}
      {!noStations && !(stations?.length > 0) ? (
        <>
          <Backdrop />
          <Box
            sx={{
              zIndex: 3,
              position: "fixed",
              margin: 0,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Spinner />
          </Box>
        </>
      ) : (
        <GoogleMap
          mapContainerStyle={style ? style : containerStyle}
          center={mapCenter}
          zoom={mapZoom}
          onLoad={(map) => {
            setMap(map);
          }}
          onZoomChanged={() => {
            if (handleZoomChanged && map) handleZoomChanged(map.getZoom());
          }}
          onCenterChanged={() => {
            if (handleCenterChanged && map) {
              handleCenterChanged(map.getCenter().toJSON());
            }
          }}
          clickableIcons={false}
          options={{
            disableDefaultUI: true,
            styles: [
              {
                elementType: "labels",
                featureType: "poi.business",
                stylers: [{ visibility: "off" }],
              },
            ],
          }}
          onClick={(e) => {
            handleClick && handleClick(e);
          }}
        >
          {LatLng && <Marker position={LatLng} />}
          {enableClustering ? (
            <MarkerClusterer
              options={clusterOptions}
              minimumClusterSize={2}
              gridSize={40}
              clusterClass={styles.cluster}
            >
              {stations?.length > 0 &&
                ((clusterer) =>
                  stations.map((station, index) => {
                    return (
                      <Marker
                        key={station._id}
                        position={{
                          lat: station.coordinates[0],
                          lng: station.coordinates[1],
                        }}
                        onClick={onViewDetail.bind(this, station._id)}
                        icon={station.tomTomId ? publicIcon : privateIcon}
                        clusterer={clusterer}
                      ></Marker>
                    );
                  }))}
            </MarkerClusterer>
          ) : (
            stations?.length > 0 &&
            stations.map((station, index) => {
              return (
                <Marker
                  key={station._id}
                  position={{
                    lat: station.coordinates[0],
                    lng: station.coordinates[1],
                  }}
                  onClick={onViewDetail.bind(this, station._id)}
                  icon={station.network ? privateIcon : publicIcon}
                ></Marker>
              );
            })
          )}
        </GoogleMap>
      )}
    </>
  ) : (
    <Spinner />
  );
}
