import React from "react";
import PublicIcon from "@mui/icons-material/Public";
import PublicOffIcon from "@mui/icons-material/PublicOff";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";

const CustomizedSwitch = styled(Switch)`
  .MuiSwitch-switchBase {
    margin: 8px;
    padding: 0;
    background-color: rgba(255, 255, 255, 0.2);
  }
  .MuiSwitch-switchBase:hover {
    margin: 8px;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

// eslint-disable-next-line react/prop-types
export default function ToggleMap({ onToggle }) {
  return (
    <FormControlLabel
      control={
        <CustomizedSwitch
          color="primary"
          checkedIcon={<PublicIcon sx={{ color: "primary.main" }} />}
          icon={<PublicOffIcon sx={{ color: "error.main" }} />}
          onChange={onToggle}
          defaultChecked={false}
        />
      }
      label="Only show bookable"
      sx={{
        position: "absolute",
        top: "75px",
        left: "20px",
        zIndex: "1",
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        borderRadius: "10px",
        padding: "0px 10px 0px 0px",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        boxShadow: "1px 2px 5px 0px rgba(0,0,0,0.5)",
      }}
    />
  );
}
