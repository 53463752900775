import React from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";

const networkList = (props) => {
  const handleViewDetail = (networkId) => {
    props.onViewDetail(networkId);
  };

  return (
    <List
      sx={{
        margin: "auto",
        padding: 1,
      }}
    >
      {props.networks.map((network, index) => {
        return (
          <ListItem
            key={index}
            sx={{
              border: "1px solid #ccc",
              margin: "5px 0",
              borderRadius: "5px",
            }}
            secondaryAction={
              <Button
                startIcon={<VisibilityIcon />}
                variant="contained"
                edge="end"
                aria-label="view"
                onClick={() => handleViewDetail(network.network._id)}
              >
                View
              </Button>
            }
          >
            <ListItemText
              primary={network.network.name}
              secondary={`Credits: €${network.credits}`}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default networkList;
