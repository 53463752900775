/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import handleErrors from "../../utils/handleErrors";

import { config } from "../../Constants";
import Spinner from "../Spinner/Spinner";
import BookingList from "./BookingList";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
const API_URL = config.api;

export const PastBookings = ({ user, setSelectedCharger }) => {
  const [bookings, setBookings] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (user) fetchPastBookings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const fetchPastBookings = () => {
    setIsLoading(true);
    const requestBody = {
      query: `
            query {
              pastBookings {
                _id
                bookingStart
                bookingEnd
                createdAt
                cost
                charger {
                  _id
                  name
                  power
                  price
                  station {
                    _id
                    name
                    address
                    coordinates
                  }
                }
                car {
                  name
                }
              }
            }
          `,
    };
    user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
        },
      })
        .then((res) => {
          return handleErrors(res);
        })
        .then((resData) => {
          const bookings = {};
          resData.data.pastBookings.forEach((booking) => {
            const startDate = booking.bookingStart.split("T")[0];
            if (!bookings[startDate]) {
              const formattedDate = new Date(startDate).toLocaleDateString(
                "en-GB",
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              );
              bookings[startDate] = {
                bookings: [],
                formattedDate: formattedDate,
              };
            }
            bookings[startDate].bookings.push(booking);
          });
          // Sort bookings by time
          // eslint-disable-next-line guard-for-in
          for (const key in bookings) {
            bookings[key].bookings.sort((b, a) => {
              return new Date(a.bookingStart) - new Date(b.bookingStart);
            });
          }

          // Sort bookings by date
          const sortedBookingDates = {};
          Object.keys(bookings)
            .sort((b, a) => {
              return new Date(a) - new Date(b);
            })
            .forEach((key) => {
              sortedBookingDates[key] = bookings[key];
            });

          setBookings(sortedBookingDates);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    });
  };
  return (
    <Box sx={{ pt: 2, pb: 2, pl: 2 }}>
      <Typography variant="h6" gutterBottom component="p">
        Previous Bookings
      </Typography>
      {isLoading ? (
        <Spinner />
      ) : bookings && Object.keys(bookings).length > 0 ? (
        <List>
          {Object.keys(bookings).map((date, index) => {
            return (
              <Box key={date} maxWidth={1000} margin={"10px"}>
                <ListSubheader sx={{ fontSize: "18px" }}>
                  {bookings[date].formattedDate}
                </ListSubheader>
                <BookingList
                  bookings={bookings[date].bookings}
                  setSelectedCharger={setSelectedCharger}
                />
                {index !== Object.keys(bookings).length - 1 && <Divider />}
              </Box>
            );
          })}
        </List>
      ) : (
        <Box sx={{ pt: 2, pb: 2, pl: 2 }}>
          <Typography variant="body1" gutterBottom component="p">
            You have no previous bookings.
          </Typography>
        </Box>
      )}
    </Box>
  );
};
