/* eslint-disable react/prop-types */

// Use react-datepicker to select time ranges as well.
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { FormattedTime, TimeDifference } from "./Utils/FormattedTime";
import { NavLink } from "react-router-dom";
import DatePicker from "react-datepicker";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Map from "../Map/Map";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import "react-datepicker/dist/react-datepicker.css";
import handleErrors from "../../utils/handleErrors";

import { config } from "../../Constants";
const API_URL = config.api;

const steps = ["Charger", "Date & Time", "Vehicle", "Confirm"];

export const BookCharger = ({
  booking,
  setBooking,
  selectedCharger,
  setSelectedCharger,
  selectedStation,
  setSelectedStation,
  user,
  modalCancelHandler,
}) => {
  const [cars, setCars] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [unavailableTimeSlots, setUnavailableTimeSlots] = useState([]);
  const [minEndTime, setMinEndTime] = useState(null);
  const [maxEndTime, setMaxEndTime] = useState(null);
  const [finishedTimes, setFinishedTimes] = useState(false);
  const [selectedCarId, setSelectedCarId] = useState("");
  const [policies, setPolicies] = useState(null);
  const [activeStep, setActiveStep] = React.useState(1);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    if (user) fetchCars();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (user && startDate) {
      fetchAvailability();
      if (selectedStation.network) {
        fetchPolicy();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  const fetchCars = () => {
    const requestBody = {
      query: `
          query {
            cars {
              _id
              name
            }
          }
        `,
    };

    user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
        },
      })
        .then((res) => {
          return handleErrors(res);
        })
        .then((resData) => {
          setCars(resData.data.cars);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const fetchAvailability = () => {
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 1);
    const requestBody = {
      query: `
          query Availability($chargerId: ID!, $startDate: String!, $endDate: String!) {
            availability(chargerId: $chargerId, startDate: $startDate, endDate: $endDate) {
              bookingStart
              bookingEnd
            }
          }
        `,
      variables: {
        chargerId: selectedCharger._id,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      },
    };

    user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
        },
      })
        .then((res) => {
          return handleErrors(res);
        })
        .then((resData) => {
          const bookings = resData.data.availability;
          const times = [];
          for (let i = 0; i < 24; i++) {
            times.push(new Date(startDate).setHours(i, 0, 0, 0));
            times.push(new Date(startDate).setHours(i, 15, 0, 0));
            times.push(new Date(startDate).setHours(i, 30, 0, 0));
            times.push(new Date(startDate).setHours(i, 45, 0, 0));
          }
          const unavailableTimeSlots = [];
          for (let i = 0; i < bookings.length; i++) {
            const now = new Date().getTime();
            const start = new Date(bookings[i].bookingStart).getTime();
            const end = new Date(bookings[i].bookingEnd).getTime();
            for (let j = 0; j < times.length; j++) {
              if ((start <= times[j] && times[j] < end) || times[j] <= now) {
                unavailableTimeSlots.push(times[j]);
              }
            }
          }
          setUnavailableTimeSlots(unavailableTimeSlots);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const fetchPolicy = () => {
    const requestBody = {
      query: `
          query policies($networkId: ID!) {
            policies(networkId: $networkId) {
              name
              value
            }
          }
        `,
      variables: {
        networkId: selectedStation.network._id,
      },
    };

    user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
        },
      })
        .then((res) => {
          return handleErrors(res);
        })
        .then((resData) => {
          const policies = resData.data.policies;
          setPolicies(policies);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const bookChargerHandler = () => {
    if (!user) {
      setSelectedCharger(null);
      return;
    }

    let carId;

    selectedCarId ? (carId = selectedCarId) : (carId = cars.at(0)._id);

    const requestBody = {
      query: `
        mutation BookCharger($chargerId: ID!, $carId: ID!, $bookingStart: String!, $bookingEnd: String! ) {
          bookCharger(bookingInput: {chargerId: $chargerId, carId: $carId, bookingStart: $bookingStart, bookingEnd: $bookingEnd}) {
            _id
            bookingStart
            bookingEnd
            createdAt
            updatedAt
          }
        }
      `,
      variables: {
        chargerId: selectedCharger._id,
        carId: carId,
        bookingStart: startTime,
        bookingEnd: endTime,
      },
    };
    user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
        },
      })
        .then((res) => {
          return handleErrors(res);
        })
        .then((resData) => {
          setSelectedStation(null);
          setSelectedCharger(null);
          setBooking(false);
        })
        .catch((err) => {
          alert(err);
          console.log(err);
        });
    });
  };

  const handleStartDate = (date) => {
    setStartDate(date);
    setStartTime(date);
  };

  const handleStartTime = (time) => {
    setStartTime(time);
    let maxConsecutiveTime = 28800000; // 8 hours
    let minConsecutiveTime = 900000; // 15 minutes
    if (policies) {
      for (let i = 0; i < policies.length; i++) {
        if (policies[i].name === "maxHours") {
          maxConsecutiveTime = Math.min(
            maxConsecutiveTime,
            policies[i].value * 3600000
          );
        }
        if (policies[i].name === "minHours") {
          minConsecutiveTime = Math.max(
            minConsecutiveTime,
            policies[i].value * 3600000
          );
        }
      }
    }
    let tempMaxEndTime = time.getTime() + maxConsecutiveTime;
    setEndTime(new Date(time.getTime() + minConsecutiveTime));
    setMinEndTime(new Date(time.getTime() + minConsecutiveTime));

    // set max end time to the next booking start time
    for (let i = 0; i < unavailableTimeSlots.length; i++) {
      if (
        unavailableTimeSlots[i] > time.getTime() &&
        unavailableTimeSlots[i] < tempMaxEndTime
      ) {
        tempMaxEndTime = unavailableTimeSlots[i];
      }
    }
    tempMaxEndTime = Math.min(
      tempMaxEndTime,
      new Date(time).setHours(23, 59, 0, 0)
    );
    setMaxEndTime(new Date(tempMaxEndTime));
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      bookChargerHandler();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 1) {
      setBooking(false);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleEndTime = (time) => {
    setEndTime(time);
    setFinishedTimes(true);
  };

  const handleChange = (event) => {
    setSelectedCarId(event.target.value);
  };

  // eslint-disable-next-line react/display-name
  const DateCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <Button
      variant="outlined"
      onClick={onClick}
      ref={ref}
      sx={{
        fontSize: "16px",
        width: "100%",
      }}
    >
      {value ? value : "Select Date"}
    </Button>
  ));

  // eslint-disable-next-line react/display-name
  const StartTimeCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <Button
      variant="outlined"
      onClick={onClick}
      ref={ref}
      sx={{
        fontSize: "16px",
        width: "100%",
      }}
    >
      {endTime ? value : "Select Time"}
    </Button>
  ));

  // eslint-disable-next-line react/display-name
  const EndTimeCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <Button
      variant="outlined"
      onClick={onClick}
      ref={ref}
      sx={{
        fontSize: "16px",
        width: "100%",
      }}
    >
      {finishedTimes ? value : "Select Time"}
    </Button>
  ));

  return (
    <Dialog
      open={booking}
      fullScreen={fullScreen}
      fullWidth
      onClose={modalCancelHandler}
      maxWidth={"xl"}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        Book Charger
        <IconButton
          aria-label="close"
          onClick={modalCancelHandler}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container columns={24} spacing={4}>
          <Grid item xs={24} lg={17} order={{ xs: 2, lg: 1 }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === 1 && (
              <>
                <h2>Select Date & Time</h2>
                <Grid container columnSpacing={5}>
                  <Grid
                    item
                    sx={{ textAlign: "center", margin: 2 }}
                    xs={12}
                    sm={3}
                  >
                    <h3 style={{ margin: 0, padding: 0 }}>Date</h3>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      minDate={new Date()}
                      maxDate={
                        new Date(new Date().setDate(new Date().getDate() + 14))
                      }
                      selected={startDate}
                      onChange={(date) => handleStartDate(date)}
                      customInput={<DateCustomInput />}
                      withPortal
                    />
                  </Grid>
                  {startDate && (
                    <Grid
                      item
                      sx={{ textAlign: "center", margin: 2, width: "100%" }}
                      xs={12}
                      sm={3}
                    >
                      <h3 style={{ margin: 0, padding: 0 }}>From</h3>
                      <DatePicker
                        selected={startTime}
                        onChange={(time) => handleStartTime(time)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        excludeTimes={unavailableTimeSlots}
                        customInput={<StartTimeCustomInput />}
                      />
                    </Grid>
                  )}
                  {startTime && minEndTime && maxEndTime && (
                    <Grid
                      item
                      sx={{ textAlign: "center", margin: 2 }}
                      xs={12}
                      sm={3}
                    >
                      <h3 style={{ margin: 0, padding: 0 }}>To</h3>
                      <DatePicker
                        selected={endTime}
                        onChange={(time) => handleEndTime(time)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        minTime={minEndTime}
                        maxTime={maxEndTime}
                        customInput={<EndTimeCustomInput />}
                      />
                    </Grid>
                  )}
                </Grid>
                {finishedTimes && (
                  <Box
                    mt={2}
                    sx={{
                      textAlign: { xs: "center", sm: "left" },
                    }}
                  >
                    <p>
                      Booking <b>{days[startDate.getDay()]}</b> for{" "}
                      <b>
                        <TimeDifference
                          startTime={startTime}
                          endTime={endTime}
                        />
                      </b>
                    </p>
                  </Box>
                )}
              </>
            )}
            {activeStep === 2 && (
              <>
                {cars && cars.length > 0 ? (
                  <Box mt={2}>
                    <h2>Select Vehicle</h2>
                    <FormControl fullWidth>
                      <InputLabel id="car-label">Select Vehicle</InputLabel>
                      <Select
                        labelId="car-label"
                        id="car"
                        value={selectedCarId}
                        label="Car"
                        onChange={handleChange}
                      >
                        {cars.map((car) => (
                          <MenuItem key={car._id} value={car._id}>
                            {car.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                ) : (
                  <i style={{ color: "red" }}>
                    <br></br>
                    You must first add a car to your account to book a charger.
                    <br></br>
                    <NavLink to="/cars">Register a car here</NavLink>
                  </i>
                )}
              </>
            )}
            {activeStep === 3 && (
              <Box>
                <h2>Booking Summary</h2>
                <p>
                  <b>
                    {startDate.toLocaleDateString("en-IE", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}{" "}
                  </b>
                </p>
                <p>
                  <b>Time: </b>
                  <FormattedTime time={startTime} />
                  {" - "}
                  <FormattedTime time={endTime} />
                  <b>
                    {" ("}
                    <TimeDifference startTime={startTime} endTime={endTime} />
                    {")"}
                  </b>
                </p>
                <p>
                  <b>Charger Location:</b> {selectedStation.address}
                </p>
                {selectedCharger.name && (
                  <p>
                    <b>Charger Label:</b> {selectedCharger.name}
                  </p>
                )}
                <p>
                  <b>Charger Type:</b> {selectedCharger.label}
                </p>
                <p>
                  <b>Charger Power:</b> {selectedCharger.power} kW
                </p>
                <p>
                  <b>Total Cost:</b>
                  {" €"}
                  {(
                    (selectedCharger.price * (endTime - startTime)) /
                    3600000
                  ).toFixed(2)}{" "}
                  {"(€"}
                  {selectedCharger.price}
                  {"/hr)"}
                </p>
              </Box>
            )}
          </Grid>

          <Grid item xs order={{ xs: 4, lg: 2 }}>
            <Divider
              sx={{ display: { xs: "none", lg: "block" }, height: "100%" }}
              orientation="vertical"
              flexItem
            />
          </Grid>
          <Grid item xs={24} lg={6} order={{ xs: 3, lg: 3 }}>
            <Divider sx={{ display: { xs: "block", lg: "none" } }} />
            <Grid container>
              <Grid item xs={12} sm={6} lg={12}>
                <h2>{selectedStation.name}</h2>
                <p>{selectedStation.address}</p>
                <img
                  src={selectedCharger.imgSrc}
                  style={{ width: "80px", float: "left", margin: "auto" }}
                  alt="connector_symbol"
                />
                <div style={{ paddingLeft: "100px" }}>
                  {selectedCharger.name && (
                    <p>{selectedCharger.name.slice(0, 18)}</p>
                  )}
                  <p>{selectedCharger.type.slice(0, 18)}</p>
                  <p>{selectedCharger.power}KW</p>
                  {selectedCharger.price ? (
                    <p>{selectedCharger.price}&euro;/hr</p>
                  ) : null}
                </div>
              </Grid>
              <Grid item xs={12} sm={6} lg={12}>
                <Map
                  style={{
                    width: "100%",
                    height: "100%",
                    minHeight: "200px",
                  }}
                  LatLng={{
                    lat: selectedStation.coordinates[0],
                    lng: selectedStation.coordinates[1],
                  }}
                  zoom={16}
                  noStations={true}
                />
              </Grid>
            </Grid>
            <Divider sx={{ display: { xs: "block", lg: "none" } }} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="inherit"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Button
          onClick={handleNext}
          disabled={
            activeStep === 1
              ? startTime && endTime
                ? false
                : true
              : activeStep === 2
              ? selectedCarId
                ? false
                : true
              : false
          }
        >
          {activeStep === steps.length - 1 ? "Book" : "Next"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
