export const chargerTypes = [
  {
    values: ["IEC62196Type2Outlet", "IEC62196Type2CableAttached", "type_2"],
    value: "type_2",
    label: "Type 2",
    imgSrc: "/images/type2.png",
  },
  {
    values: ["Chademo", "chademo"],
    value: "chademo",
    label: "CHAdeMO",
    imgSrc: "/images/chademo.png",
  },
  {
    values: ["IEC62196Type2CCS", "ccs"],
    value: "ccs",
    label: "CCS2",
    imgSrc: "/images/ccs2.png",
  },
  {
    values: ["other"],
    value: "other",
    label: "Other",
  },
];

export const convertChargerTypes = (chargerValue) => {
  for (let i = 0; i < chargerTypes.length; i++) {
    for (let j = 0; j < chargerTypes[i].values.length; j++) {
      if (chargerValue === chargerTypes[i].values[j]) {
        return {
          label: chargerTypes[i].label,
          imgSrc: chargerTypes[i].imgSrc,
        };
      }
    }
  }

  return {
    label: chargerValue,
    imgSrc: null,
  };
};
