import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Alert,
} from "@mui/material";
import validator from "validator";
import handleErrors from "../../utils/handleErrors";

import { config } from "../../Constants";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { useSnackbar } from "notistack";
import EnterPassword from "./utils/EnterPassword";
const API_URL = config.api;

export default function Register() {
  const { enqueueSnackbar } = useSnackbar();
  const auth = getAuth();

  const [firstNameVal, setFirstNameVal] = React.useState("");
  const [lastNameVal, setLastNameVal] = React.useState("");
  const [phoneNumberVal, setPhoneNumberVal] = React.useState("");
  const [emailVal, setEmailVal] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [validPassword, setValidPassword] = React.useState(false);

  const [error, setError] = React.useState(null);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(null);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const firstName = data.get("firstName");
    const lastName = data.get("lastName");
    const phoneNumber = data.get("phoneNumber");
    const email = data.get("email");
    const allowExtraEmails = data.has("allowExtraEmails");

    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredentials) => {
        const requestBody = {
          query: `
          mutation CreateUser($firebaseId: String!, $firstName: String!, $lastName: String!, $phoneNumber: String!, $email: String!, $password: String!, $allowExtraEmails: Boolean!) {
            createUser(userInput: {firebaseId: $firebaseId, firstName: $firstName, lastName: $lastName, phoneNumber: $phoneNumber, email: $email, password: $password, allowExtraEmails: $allowExtraEmails}) {
              userId
              token
            }
          }
          `,
          variables: {
            firebaseId: userCredentials.user.uid,
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            email: email,
            password: password,
            allowExtraEmails: allowExtraEmails,
          },
        };

        fetch(API_URL, {
          method: "POST",
          body: JSON.stringify(requestBody),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then(async (res) => {
            return handleErrors(res);
          })
          .then((resData) => {
            if (resData.data.createUser) {
              enqueueSnackbar("Account created successfully", {
                variant: "success",
              });
              sendEmailVerification(auth.currentUser).then(() => {
                enqueueSnackbar("Email verification sent", {
                  variant: "info",
                });
              });
            }
          })
          .catch((err) => {
            setError(err.message);
            setTimeout(() => {
              setError(null);
            }, 6000);
          });
      })
      .catch((error) => {
        if (error.code === "auth/email-already-in-use") {
          setError("Email already in use");
        } else {
          setError("Unknown Error");
        }
      });
  };

  const handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "firstName") {
      if (value.length === 0) {
        setFirstNameVal("First Name can't be empty");
      } else {
        setFirstNameVal("");
      }
    } else if (name === "lastName") {
      if (value.length === 0) {
        setLastNameVal("Last Name can't be empty");
      } else {
        setLastNameVal("");
      }
    } else if (name === "phoneNumber") {
      if (value.length === 0) {
        setPhoneNumberVal("Phone Number can't be empty");
      } else if (!validator.isMobilePhone(value, "en-IE")) {
        setPhoneNumberVal("Phone Number is invalid");
      } else {
        setPhoneNumberVal("");
      }
    } else if (name === "email") {
      if (value.length === 0) {
        setEmailVal("Email can't be empty");
      } else if (!validator.isEmail(value)) {
        setEmailVal("Email is invalid");
      } else {
        setEmailVal("");
      }
    }
  };

  return (
    <>
      <Typography component="h1" variant="h5">
        Sign up
      </Typography>
      {error && (
        <Alert
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%", mt: 2 }}
        >
          {error}
        </Alert>
      )}

      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="given-name"
              name="firstName"
              required
              fullWidth
              id="firstName"
              label="First Name"
              autoFocus
              onChange={(e) => handleUserInput(e)}
              error={firstNameVal !== ""}
              helperText={firstNameVal}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="family-name"
              onChange={(e) => handleUserInput(e)}
              error={lastNameVal !== ""}
              helperText={lastNameVal}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="phoneNumber"
              label="Phone Number"
              name="phoneNumber"
              autoComplete="tel"
              onChange={(e) => handleUserInput(e)}
              error={phoneNumberVal !== ""}
              helperText={phoneNumberVal}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={(e) => handleUserInput(e)}
              error={emailVal !== ""}
              helperText={emailVal}
            />
          </Grid>
          <Grid item xs={12}>
            <EnterPassword
              password={password}
              setPassword={setPassword}
              setValidPassword={setValidPassword}
              includeProgress
              includeConfirmPassword
              noAutocomplete
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="allowExtraEmails"
                  value="allowExtraEmails"
                  color="primary"
                />
              }
              label="I want to stay up to date with new features emailed to me."
            />
          </Grid>
        </Grid>
        <Button
          disabled={
            !validPassword ||
            firstNameVal !== "" ||
            lastNameVal !== "" ||
            phoneNumberVal !== "" ||
            emailVal !== ""
          }
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign Up
        </Button>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Link component={RouterLink} to="/auth/login" variant="body2">
              Already have an account? Sign in
            </Link>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
