/* eslint-disable react/prop-types */
import React, { useEffect } from "react";

import Backdrop from "../components/Backdrop/Backdrop";
import Spinner from "../components/Spinner/Spinner";

import NetworkList from "../components/Networks/NetworkList";
import { ViewNetwork } from "../components/Networks/ViewNetwork";
import { CreateEditNetwork } from "../components/Networks/CreateEditNetwork";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import AddIcon from "@mui/icons-material/Add";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

import handleErrors from "../utils/handleErrors";

import {
  TextField,
  InputAdornment,
  Tooltip,
  Typography,
  Box,
} from "@mui/material";

import { config } from "../Constants";
const API_URL = config.api;

const NetworksPage = ({ user }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [creating, setCreating] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [selectedNetwork, setSelectedNetwork] = React.useState(null);
  const [networks, setNetworks] = React.useState([]);
  const [inviteCode, setInviteCode] = React.useState("");

  useEffect(() => {
    if (user) fetchNetworks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const fetchNetworks = () => {
    setIsLoading(true);
    const requestBody = {
      query: `
          query {
            networks {
              network {
                _id
                name
                creator {
                  _id
                  firebaseId
                }
                privacy
                invite {
                  code
                }
                policies {
                  name
                  value
                }
                numMembers
              }
              credits
            }
          }
        `,
    };

    user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
        },
      })
        .then((res) => {
          return handleErrors(res);
        })
        .then((resData) => {
          const networks = resData.data.networks;
          setNetworks(networks);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    });
  };

  const modalJoinHandler = () => {
    setIsLoading(true);
    const requestBody = {
      query: `
          mutation JoinNetwork($code: String!) {
            joinNetwork(code: $code) {
              _id
              name
              creator {
                _id
                firebaseId
              }
              privacy
              invite {
                code
              }
              policies {
                name
                value
              }
              numMembers
            }
          }
        `,
      variables: {
        code: inviteCode,
      },
    };

    user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
        },
      })
        .then((res) => {
          return handleErrors(res);
        })
        .then((resData) => {
          const updatedNetworks = networks;
          updatedNetworks.push({
            network: resData.data.joinNetwork,
            credits: 0.0,
          });
          setNetworks(updatedNetworks);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    });
  };

  const modalCancelHandler = () => {
    setCreating(false);
    setEditing(false);
    setSelectedNetwork(null);
  };

  const viewNetworkDetailsHandler = (networkId) => {
    const selectedNetwork = networks.find((n) => n.network._id === networkId);
    setSelectedNetwork(selectedNetwork);
  };

  return (
    <Box maxWidth={800} margin={"auto"}>
      <Typography variant="h4" pt={10} ml={2}>
        Networks
      </Typography>
      {isLoading && <Spinner />}
      {(creating || selectedNetwork) && <Backdrop />}
      {(creating || editing) && (
        <CreateEditNetwork
          user={user}
          setCreating={setCreating}
          setEditing={setEditing}
          selectedNetwork={selectedNetwork}
          networks={networks}
          setNetworks={setNetworks}
          viewNetworkDetailsHandler={viewNetworkDetailsHandler}
          modalCancelHandler={modalCancelHandler}
        />
      )}

      {selectedNetwork && !editing && (
        <ViewNetwork
          viewNetwork={true}
          networks={networks}
          setEditing={setEditing}
          setNetworks={setNetworks}
          selectedNetwork={selectedNetwork}
          user={user}
          modalCancelHandler={modalCancelHandler}
        />
      )}
      <Grid
        container
        columnSpacing={2}
        sx={{
          alignItems: "center",
          margin: "15px auto 0 auto",
          maxWidth: "600px",
        }}
      >
        <Grid item xs={12} sm={7} pr={3}>
          <TextField
            size="small"
            margin="normal"
            fullWidth
            id="join"
            helperText="Enter the invite code of a network"
            label="Join Network"
            name="join"
            onChange={(e) => setInviteCode(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ marginRight: "-14px" }}>
                  <Tooltip title="Join Network">
                    <Button
                      color="info"
                      onClick={modalJoinHandler}
                      variant="contained"
                      startIcon={<GroupAddIcon />}
                      sx={{ padding: "9px 16px", zIndex: 1 }}
                    >
                      Join
                    </Button>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={5} pr={3}>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            color="info"
            onClick={() => setCreating(true)}
            sx={{ marginBottom: "1rem", width: "100%" }}
          >
            Create Network
          </Button>
        </Grid>
      </Grid>
      {networks.length > 0 && (
        <NetworkList
          networks={networks}
          onViewDetail={viewNetworkDetailsHandler}
          authUserId={user.uid}
        />
      )}
    </Box>
  );
};

export default NetworksPage;
