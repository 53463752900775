/* eslint-disable react/prop-types */
import React from "react";
import Map from "../../Map/Map";

export const Location = ({
  name,
  setName,
  address,
  setAddress,
  latLng,
  setLatLng,
}) => {
  const mapStyle = {
    width: "100%",
    height: "300px",
  };

  const handleClick = (e) => {
    setLatLng({ lat: e.latLng.lat(), lng: e.latLng.lng() });
  };

  return (
    <Map
      autocomplete
      style={mapStyle}
      handleClick={handleClick}
      LatLng={latLng}
      zoom={16}
      setLatLng={setLatLng}
      name={name}
      setName={setName}
      address={address}
      setAddress={setAddress}
      noStations={true}
    />
  );
};
