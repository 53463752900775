/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import { Box, TextField, Typography } from "@mui/material";
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
} from "firebase/auth";

export const Reauthenticate = ({
  user,
  setReauth,
  modalCancelHandler,
  onReauth,
  titleText,
  buttonText,
}) => {
  const [password, setPassword] = useState(null);
  const [error, setError] = React.useState(null);
  getAuth();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleReauth = () => {
    const credential = EmailAuthProvider.credential(user.email, password);
    reauthenticateWithCredential(user, credential)
      .then(() => {
        setReauth(false);
        onReauth();
      })
      .catch((error) => {
        console.log("Error reauthenticating", error);
        setError("Incorrect password");
        setPassword(null);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setError(null);
  };

  return (
    <Dialog
      open
      fullScreen={fullScreen}
      fullWidth
      onClose={() => {
        modalCancelHandler();
      }}
      maxWidth={"sm"}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {titleText}
        <IconButton
          aria-label="close"
          onClick={() => {
            modalCancelHandler();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box
          noValidate
          autoComplete="off"
          sx={{
            maxWidth: "100%",
          }}
        >
          {error && (
            <Alert onClose={handleClose} severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <Typography variant="body1" gutterBottom component="p">
            Enter your password to continue
          </Typography>
          <TextField
            fullWidth
            id="password"
            label="Current password"
            type="password"
            variant="outlined"
            value={password ? password : ""}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            modalCancelHandler();
          }}
        >
          Close
        </Button>
        <Button color="error" onClick={handleReauth}>
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
