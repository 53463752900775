/* eslint-disable react/prop-types */

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { MenuItem, Box, TextField, ListItemText } from "@mui/material";
import handleErrors from "../../utils/handleErrors";

import { config } from "../../Constants";
const API_URL = config.api;

export const CreateEditNetwork = ({
  user,
  setCreating,
  setEditing,
  networks,
  setNetworks,
  selectedNetwork,
  viewNetworkDetailsHandler,
  modalCancelHandler,
}) => {
  const [name, setName] = useState(null);
  const [privacy, setPrivacy] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (selectedNetwork) {
      setName(selectedNetwork.network.name);
      setPrivacy(selectedNetwork.network.privacy);
    }
  }, [selectedNetwork]);

  const modalCreateHandler = () => {
    const requestBody = {
      query: `
          mutation CreateNetwork($name: String!, $privacy: String!) {
            createNetwork(networkInput: {name: $name, privacy: $privacy}) {
              network {
                _id
                name
                creator {
                  _id
                  firebaseId
                }
                privacy
                invite {
                  code
                }
                policies {
                  name
                  value
                }
                numMembers
              }
              credits
            }
          }
        `,
      variables: {
        name: name,
        privacy: privacy,
      },
    };

    user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
        },
      })
        .then((res) => {
          return handleErrors(res);
        })
        .then((resData) => {
          const updatedNetworks = [...networks];
          updatedNetworks.push(resData.data.createNetwork);
          setNetworks(updatedNetworks);
          viewNetworkDetailsHandler(resData.data.createNetwork.network._id);
          setCreating(false);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const modalEditHandler = () => {
    const requestBody = {
      query: `
          mutation UpdateNetwork($networkId: ID!, $name: String!, $privacy: String!) {
            updateNetwork(networkId: $networkId, networkInput: {name: $name, privacy: $privacy}) {
              _id
              name
              creator {
                _id
                firebaseId
              }
              privacy
              invite {
                code
              }
              policies {
                name
                value
              }
              numMembers
            }
          }
        `,
      variables: {
        networkId: selectedNetwork.network._id,
        name: name,
        privacy: privacy,
      },
    };

    user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
        },
      })
        .then((res) => {
          return handleErrors(res);
        })
        .then((resData) => {
          const updatedNetwork = resData.data.updateNetwork;
          const updatedNetworks = [...networks];

          const networkIndex = updatedNetworks.findIndex(
            (networkDetails) =>
              networkDetails.network._id === selectedNetwork.network._id
          );
          updatedNetworks[networkIndex].network = updatedNetwork;
          setNetworks(updatedNetworks);
          viewNetworkDetailsHandler(resData.data.updateNetwork._id);
          setEditing(false);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  return (
    <Dialog
      open
      fullScreen={fullScreen}
      fullWidth
      onClose={() => {
        modalCancelHandler();
      }}
      maxWidth={"sm"}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {selectedNetwork ? "Edit" : "Create"} Network
        <IconButton
          aria-label="close"
          onClick={() => {
            modalCancelHandler();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box
          noValidate
          autoComplete="off"
          sx={{
            maxWidth: "100%",
          }}
        >
          <TextField
            id="name"
            label="Network Name"
            variant="outlined"
            value={name ? name : ""}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            sx={{ mt: 2 }}
          />

          <TextField
            id="outlined-select-privacy"
            select
            label="Network Privacy"
            helperText="Please select the privacy of your network"
            value={privacy ? privacy : ""}
            onChange={(e) => {
              setPrivacy(e.target.value);
            }}
            fullWidth
            sx={{ mt: 2 }}
          >
            <MenuItem value="public">
              <ListItemText
                primary="Anyone with the invite"
                secondary="Anyone on the Internet with the invite code can join"
              />
            </MenuItem>
            <MenuItem value="private">
              <ListItemText
                primary="Restricted"
                secondary="Only invited people can join with the invite code"
              />
            </MenuItem>
          </TextField>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            modalCancelHandler();
          }}
        >
          Close
        </Button>
        <Button
          onClick={selectedNetwork ? modalEditHandler : modalCreateHandler}
        >
          {selectedNetwork ? "Edit" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
