import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Link from "@mui/material/Link";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ElectricCarIcon from "@mui/icons-material/ElectricCar";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EvStationIcon from "@mui/icons-material/EvStation";
import HubIcon from "@mui/icons-material/Hub";
import PersonIcon from "@mui/icons-material/Person";
import { Divider, Icon } from "@mui/material";
import { signOut, getAuth } from "firebase/auth";
import { useSnackbar } from "notistack";

const settings = [
  { name: "Profile", link: "/profile", icon: <PersonIcon /> },
  { name: "Bookings", link: "/bookings", icon: <CalendarMonthIcon /> },
  { name: "Cars", link: "/cars", icon: <ElectricCarIcon /> },
];

// eslint-disable-next-line react/prop-types
function Navigation({ user }) {
  const { enqueueSnackbar } = useSnackbar();
  const auth = getAuth();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleSignOut = () => {
    signOut(auth);
    enqueueSnackbar("Signed out successfully", { variant: "success" });
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="fixed" style={{ zIndex: 3 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <a href="https://plugnexus.com">
            <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
              <img
                src="/icon-white-transparent.png"
                alt="PlugNexus"
                height="40"
              />
            </Box>
          </a>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="https://plugnexus.com"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              color: "inherit",
              textDecoration: "none",
            }}
          >
            PlugNexus
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <Link
                component={RouterLink}
                to="/chargers"
                underline="hover"
                key="Chargers"
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Icon sx={{ mr: 1 }}>
                    <EvStationIcon />
                  </Icon>
                  Chargers
                </MenuItem>
              </Link>
              {user && (
                <Link
                  component={RouterLink}
                  to="/networks"
                  underline="hover"
                  key="Networks"
                >
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Icon sx={{ mr: 1 }}>
                      <HubIcon />
                    </Icon>
                    Networks
                  </MenuItem>
                </Link>
              )}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <Box sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}>
              <img
                src="/icon-white-transparent.png"
                alt="PlugNexus"
                height="35"
              />
            </Box>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                color: "inherit",
                textDecoration: "none",
              }}
            >
              PlugNexus
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
            <Button
              component={RouterLink}
              key="Chargers"
              onClick={handleCloseNavMenu}
              sx={{ mr: 1, my: 1, color: "white", display: "flex" }}
              to="/chargers"
            >
              <Icon sx={{ mr: 0.5, pb: 1.3 }} size="small">
                <EvStationIcon />
              </Icon>
              <Typography variant="h5" fontSize="14px">
                Chargers
              </Typography>
            </Button>

            {user && (
              <Button
                component={RouterLink}
                key="Networks"
                onClick={handleCloseNavMenu}
                sx={{ mr: 1, my: 1, color: "white", display: "flex" }}
                to="/networks"
              >
                <Icon sx={{ mr: 0.5, pb: 1.3 }} size="small">
                  <HubIcon />
                </Icon>
                <Typography variant="h5" fontSize="14px">
                  Networks
                </Typography>
              </Button>
            )}
          </Box>

          <Box sx={{ flexGrow: 0, ml: 2 }}>
            {user ? (
              <>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <AccountCircleIcon
                      style={{ color: "white", fontSize: 40 }}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px", p: "0px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <Link
                      component={RouterLink}
                      to={setting.link}
                      underline="hover"
                      key={setting.name}
                    >
                      <MenuItem onClick={handleCloseUserMenu}>
                        <Icon sx={{ mr: 1, mb: "3px" }}>{setting.icon}</Icon>
                        {setting.name}
                      </MenuItem>
                    </Link>
                  ))}
                  <Link
                    href="#"
                    onClick={() => handleSignOut(auth)}
                    underline="hover"
                    key="logout"
                  >
                    <MenuItem onClick={handleCloseUserMenu}>
                      <Icon sx={{ mr: 1, mb: "3px" }}>
                        <LogoutIcon />
                      </Icon>
                      {"Logout"}
                    </MenuItem>
                  </Link>
                  <Divider />
                  <Link
                    href="https://plugnexus.com"
                    underline="hover"
                    key="go_home"
                  >
                    <MenuItem onClick={handleCloseUserMenu} sx={{ pb: 0 }}>
                      <Icon sx={{ mb: "10px" }}>
                        <ChevronLeftIcon
                          sx={{ color: "text.secondary" }}
                          fontSize="small"
                        />
                      </Icon>
                      <Typography variant="caption" color="text.secondary">
                        {"Back Home"}
                      </Typography>
                    </MenuItem>
                  </Link>
                </Menu>
              </>
            ) : (
              <Button
                variant="contained"
                color="success"
                href="/auth/login"
                startIcon={<LoginIcon />}
              >
                Login
              </Button>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navigation;
