/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Box from "@mui/material/Box";
import { Location } from "./CreateEdit/Location";
import { Chargers } from "./CreateEdit/Chargers";
import { Network } from "./CreateEdit/Network";

import handleErrors from "../../utils/handleErrors";

import { config } from "../../Constants";
const API_URL = config.api;

const steps = ["Network", "Location", "Chargers"];

export const CreateEditStation = ({
  latLng,
  setLatLng,
  creating,
  privateStations,
  setPrivateStations,
  selectedStation,
  setSelectedStation,
  setEditing,
  setCreating,
  setLoading,
  modalCancelHandler,
  user,
}) => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [chargers, setChargers] = useState([]);
  const [activeStep, setActiveStep] = React.useState(0);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (selectedStation) {
      setName(selectedStation.name);
      setAddress(selectedStation.address);
      setChargers(selectedStation.chargers);
    }
  }, [selectedStation]);

  const modalCreateHandler = () => {
    setLoading(true);
    setCreating(false);
    const lat = +latLng.lat;
    const lng = +latLng.lng;

    const network = selectedNetwork;

    const requestBody = {
      query: `
        mutation CreateStation($name: String! $coordinates: [Float!]! $address: String!, $description: String, $network: ID) {
          createStation(stationInput: {name: $name, coordinates: $coordinates, address: $address, description: $description, network: $network}) {
            _id
            name
            coordinates
            address
            description
            creator {
              _id
            }
            network {
              _id
              name
            }
          }
        }
      `,
      variables: {
        name: name,
        coordinates: [lat, lng],
        address: address,
        description: null,
        network: network,
      },
    };

    user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
        },
      })
        .then((res) => {
          return handleErrors(res);
        })
        .then((resData) => {
          const updatedPrivateStations = [...privateStations];
          const newStation = {
            _id: resData.data.createStation._id,
            name: resData.data.createStation.name,
            coordinates: resData.data.createStation.coordinates,
            address: resData.data.createStation.address,
            description: resData.data.createStation.description,
            creator: {
              _id: resData.data.createStation.creator._id,
              firebaseId: user.uid,
            },
            chargers: [],
            network: resData.data.createStation.network,
          };

          // eslint-disable-next-line array-callback-return
          chargers.map((charger) => {
            const requestBody = {
              query: `
              mutation CreateCharger($station: ID!, $name: String, $type: String!, $power: Float!, $price: Float!) {
                createCharger(chargerInput: {station: $station, name: $name, type: $type, power: $power, price: $price}) {
                  _id
                  name
                  type
                  power
                  price
                }
              }
            `,
              variables: {
                station: resData.data.createStation._id,
                name: charger.name,
                type: charger.type,
                power: +charger.power,
                price: +charger.price,
              },
            };

            fetch(API_URL, {
              method: "POST",
              body: JSON.stringify(requestBody),
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + idToken,
              },
            })
              .then((res) => {
                return handleErrors(res);
              })
              .then((resData) => {
                newStation.chargers.push({
                  _id: resData.data.createCharger._id,
                  name: resData.data.createCharger.name,
                  type: resData.data.createCharger.type,
                  power: resData.data.createCharger.power,
                  price: resData.data.createCharger.price,
                });
              })
              .catch((err) => {
                console.log(err);
              });
          });
          updatedPrivateStations.push(newStation);
          setLatLng(null);
          setPrivateStations(updatedPrivateStations);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    });
  };

  const modalDeleteHandler = () => {
    setLoading(true);
    const requestBody = {
      query: `
          mutation DeleteStation($id: ID!) {
            deleteStation(stationId: $id) {
            _id
            }
          }
        `,
      variables: {
        id: selectedStation._id,
      },
    };
    user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
        },
      })
        .then((res) => {
          return handleErrors(res);
        })
        .then(() => {
          const updatedPrivateStations = privateStations.filter((station) => {
            return station._id !== selectedStation._id;
          });
          setPrivateStations(updatedPrivateStations);
          setSelectedStation(null);
          setEditing(false);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    });
  };

  const modalUpdateHandler = () => {
    setLoading(true);
    const lat = +latLng.lat;
    const lng = +latLng.lng;
    const network = selectedNetwork;

    const requestBody = {
      query: `
        mutation UpdateStation($stationId: ID!, $name: String!, $coordinates: [Float!]!, $address: String!, $description: String, $network: ID) {
          updateStation(stationInput: {stationId: $stationId, name: $name, coordinates: $coordinates, address: $address, description: $description, network: $network}) {
            _id
            name
            coordinates
            address
            description
            creator {
              _id
            }
            network {
              _id
              name
            }
          }
        }
      `,
      variables: {
        stationId: selectedStation._id,
        name: name,
        coordinates: [lat, lng],
        address: address,
        description: null,
        network: network,
      },
    };

    user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
        },
      })
        .then((res) => {
          return handleErrors(res);
        })
        .then((resData) => {
          const updatedStation = {
            _id: resData.data.updateStation._id,
            name: resData.data.updateStation.name,
            coordinates: resData.data.updateStation.coordinates,
            address: resData.data.updateStation.address,
            description: resData.data.updateStation.description,
            creator: {
              _id: resData.data.updateStation.creator._id,
              firebaseId: user.uid,
            },
            network: resData.data.updateStation.network,
          };

          // check if there are any deleted chargers
          const deletedChargers = selectedStation.chargers.filter((charger) => {
            return !chargers.find((c) => c._id === charger._id);
          });

          // delete chargers
          // eslint-disable-next-line array-callback-return
          deletedChargers.map((charger) => {
            const requestBody = {
              query: `
              mutation DeleteCharger($id: ID!) {
                deleteCharger(chargerId: $id) {
                _id
                }
              }
            `,
              variables: {
                id: charger._id,
              },
            };
            fetch(API_URL, {
              method: "POST",
              body: JSON.stringify(requestBody),
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + idToken,
              },
            })
              .then((res) => {
                return handleErrors(res);
              })
              .catch((err) => {
                console.log(err);
              });
          });
          const newChargers = [];

          // eslint-disable-next-line array-callback-return
          chargers.map((charger) => {
            let requestBody;
            if (charger._id) {
              requestBody = {
                query: `
              mutation UpdateCharger($chargerId: ID!, $name: String, $station: ID!, $type: String!, $power: Float!, $price: Float!) {
                updateCharger(chargerInput: {chargerId: $chargerId, name: $name, station: $station, type: $type, power: $power, price: $price}) {
                  _id
                  name
                  type
                  power
                  price
                }
              }
            `,
                variables: {
                  chargerId: charger._id,
                  station: resData.data.updateStation._id,
                  name: charger.name,
                  type: charger.type,
                  power: +charger.power,
                  price: +charger.price,
                },
              };
            } else {
              requestBody = {
                query: `
              mutation CreateCharger($station: ID!, $name: String, $type: String!, $power: Float!, $price: Float!) {
                createCharger(chargerInput: {station: $station, name: $name, type: $type, power: $power, price: $price}) {
                  _id
                  name
                  type
                  power
                  price
                }
              }
            `,
                variables: {
                  station: resData.data.updateStation._id,
                  name: charger.name,
                  type: charger.type,
                  power: +charger.power,
                  price: +charger.price,
                },
              };
            }

            fetch(API_URL, {
              method: "POST",
              body: JSON.stringify(requestBody),
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + idToken,
              },
            })
              .then((res) => {
                return handleErrors(res);
              })
              .then((resData) => {
                if (charger._id) {
                  newChargers.push(resData.data.updateCharger);
                } else {
                  newChargers.push(resData.data.createCharger);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          });
          updatedStation.chargers = [...newChargers];
          const updatedPrivateStations = [...privateStations];
          const stationPrivateIndex = updatedPrivateStations.findIndex(
            (station) => station._id === selectedStation._id
          );
          updatedPrivateStations[stationPrivateIndex] = updatedStation;
          setPrivateStations(updatedPrivateStations);
          setSelectedStation(null);
          setEditing(false);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      creating ? modalCreateHandler() : modalUpdateHandler();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (!creating && activeStep === 0) {
      setEditing(false);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Dialog
      open
      fullScreen={fullScreen}
      fullWidth
      onClose={modalCancelHandler}
      maxWidth={"lg"}
      aria-labelledby="responsive-dialog-title"
      style={{ zIndex: 3 }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {creating ? "Create Station" : "Edit Station"}
        <IconButton
          aria-label="close"
          onClick={modalCancelHandler}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === 0 && (
          <Box>
            <Network
              selectedStation={selectedStation}
              setSelectedNetwork={setSelectedNetwork}
              user={user}
            />
          </Box>
        )}
        {activeStep === 1 && (
          <Box mt={3}>
            <Location
              name={name}
              setName={setName}
              address={address}
              setAddress={setAddress}
              latLng={latLng}
              setLatLng={setLatLng}
            />
          </Box>
        )}
        {activeStep === 2 && (
          <Box mt={3}>
            <Chargers chargers={chargers} setChargers={setChargers} />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="inherit"
          disabled={creating && activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        {!creating && (
          <Button color="error" onClick={modalDeleteHandler}>
            Delete
          </Button>
        )}
        <Button
          onClick={handleNext}
          disabled={
            activeStep === 0
              ? selectedNetwork !== null && selectedNetwork !== ""
                ? false
                : true
              : activeStep === 1
              ? name !== "" && address !== "" && latLng.lat !== 0
                ? false
                : true
              : activeStep === 2
              ? (selectedStation && chargers !== selectedStation.chargers) ||
                (!selectedStation && chargers.length > 0)
                ? false
                : true
              : false
          }
        >
          {activeStep === steps.length - 1
            ? creating
              ? "Create"
              : "Save Changes"
            : "Next"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
