import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Alert,
} from "@mui/material";
import validator from "validator";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import ResetPassword from "./utils/PasswordReset";
import { useSnackbar } from "notistack";
import EnterPassword from "./utils/EnterPassword";

// eslint-disable-next-line react/prop-types
export default function Login() {
  const { enqueueSnackbar } = useSnackbar();

  const auth = getAuth();
  const [reset, setReset] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [emailVal, setEmailVal] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [validPassword, setValidPassword] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(null);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email");

    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        enqueueSnackbar("Signed in successfully", { variant: "success" });
      })
      .catch((error) => {
        if (error.code === "auth/invalid-login-credentials") {
          setError("Invalid login credentials");
        } else {
          setError("Unknown error");
        }
      });
  };

  const handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "email") {
      if (value.length === 0) {
        setEmailVal("Email can't be empty");
      } else if (!validator.isEmail(value)) {
        setEmailVal("Email is invalid");
      } else {
        setEmailVal("");
      }
    }
  };

  return (
    <>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>

      {error && (
        <Alert
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%", mt: 2 }}
        >
          {error}
        </Alert>
      )}
      {!reset ? (
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleUserInput}
            error={emailVal !== ""}
            helperText={emailVal}
            sx={{ mb: 2 }}
          />
          <EnterPassword
            password={password}
            setPassword={setPassword}
            setValidPassword={setValidPassword}
          />
          <Button
            disabled={emailVal !== "" || !validPassword}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
          >
            Sign In
          </Button>
        </Box>
      ) : (
        <ResetPassword setReset={setReset} />
      )}

      <Grid container>
        <Grid item xs>
          <Link
            component={RouterLink}
            onClick={() => setReset(!reset)}
            variant="body2"
          >
            {reset ? "Back to Login" : "Forgot password?"}
          </Link>
        </Grid>
        <Grid item>
          {!reset && (
            <Link component={RouterLink} to="/auth/register" variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          )}
        </Grid>
      </Grid>
    </>
  );
}
