/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import { TextField, MenuItem } from "@mui/material";

import { config } from "../../../Constants";
import handleErrors from "../../../utils/handleErrors";
const API_URL = config.api;

export const Network = ({ selectedStation, setSelectedNetwork, user }) => {
  const [networkOptions, setNetworkOptions] = useState(null);

  useEffect(() => {
    fetchNetworks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchNetworks = () => {
    const requestBody = {
      query: `
          query {
            networks {
              network {
                _id
                name
                creator {
                  _id
                  firebaseId
                }
              }
            }
          }
        `,
    };

    user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
        },
      })
        .then((res) => {
          return handleErrors(res);
        })
        .then((resData) => {
          const networkOptions = [];
          // eslint-disable-next-line array-callback-return
          resData.data.networks.map((networkDetails) => {
            if (networkDetails.network.creator.firebaseId === user.uid) {
              if (
                selectedStation &&
                selectedStation.network &&
                networkDetails.network._id === selectedStation.network._id
              ) {
                setSelectedNetwork(networkDetails.network._id);
                networkOptions.push({
                  label: networkDetails.network.name,
                  value: networkDetails.network._id,
                  disabled: true,
                });
              } else {
                networkOptions.push({
                  label: networkDetails.network.name,
                  value: networkDetails.network._id,
                });
              }
            }
          });
          if (networkOptions.length === 0) {
            setNetworkOptions(null);
          } else {
            setNetworkOptions(networkOptions);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const handleChange = (e) => {
    setSelectedNetwork(e.target.value);
  };

  return (
    <Box>
      <h3>Choose a Network for this Charging Station</h3>
      <p>All Charging Stations must be created within charging networks.</p>
      {!networkOptions ? (
        <Box>
          <p color="error">
            {
              "You don't own any Charging Networks, you must first create a Network before you can create a new Charging Station."
            }
          </p>
          <p>
            <NavLink to="/networks">Create Network Here</NavLink>
          </p>
        </Box>
      ) : (
        <TextField
          id="outlined-select-network"
          select
          label="Select Network"
          defaultValue={
            selectedStation && selectedStation.network
              ? selectedStation.network._id
              : "none"
          }
          helperText="Please select the network you want to add this charging station to"
          onChange={handleChange}
        >
          {!selectedStation && (
            <MenuItem key="none" value="none" disabled>
              Choose Network
            </MenuItem>
          )}
          {networkOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    </Box>
  );
};
