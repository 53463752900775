/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  List,
  ListSubheader,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Grid,
  Paper,
  Typography,
  Alert,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { policyTypes } from "../Utils/PolicyTypes";
import { config } from "../../../Constants";
import handleErrors from "../../../utils/handleErrors";
const API_URL = config.api;

export const Policies = ({ networks, setNetworks, selectedNetwork, user }) => {
  const [networkPolicies, setNetworkPolicies] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [policyList, setPolicyList] = useState(
    policyTypes.map((type) => {
      return {
        name: type.name,
        value: type.value,
        used: false,
      };
    })
  );

  useEffect(() => {
    const updatedPolicyList = [...policyList];
    if (selectedNetwork.network.policies != null) {
      selectedNetwork.network.policies.forEach((policy) => {
        const index = updatedPolicyList.findIndex(
          (type) => type.value === policy.name
        );
        if (index !== -1) updatedPolicyList[index].used = true;
      });
    }
    setNetworkPolicies(selectedNetwork.network.policies);
    setPolicyList(updatedPolicyList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addPolicyHandler = () => {
    let updatedPolicies = [];
    if (networkPolicies != null) {
      updatedPolicies = [...networkPolicies];
    }
    updatedPolicies.push({
      name: "",
      value: "",
    });
    setNetworkPolicies(updatedPolicies);
  };

  const updatedPolicyHandler = (type, value, index) => {
    const updatedPolicies = [...networkPolicies];
    const updatedPolicyList = [...policyList];
    if (type === "name") {
      const oldPolicyType = updatedPolicies[index].name;
      for (let i = 0; i < updatedPolicyList.length; i++) {
        if (
          oldPolicyType != null &&
          updatedPolicyList[i].value === oldPolicyType
        ) {
          updatedPolicyList[i].used = false;
        }
        if (updatedPolicyList[i].value === value) {
          updatedPolicyList[i].used = true;
        }
      }

      updatedPolicies[index].name = value;
    } else if (type === "value") {
      updatedPolicies[index].value = value;
    }
    setNetworkPolicies(updatedPolicies);
    setUnsavedChanges(true);
  };

  const modalSavePolicyHandler = () => {
    const requestBody = {
      query: `
          mutation updatePolicies($id: ID! $policies: [PolicyInput!]!) {
            updatePolicies(networkId: $id, policies: $policies) {
              _id
              policies {
                name
                value
              }
            }
          }
        `,
      variables: {
        id: selectedNetwork.network._id,
        policies: networkPolicies,
      },
    };
    user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
        },
      })
        .then((res) => {
          return handleErrors(res);
        })
        .then((resData) => {
          const updatedNetworks = [...networks];
          const updatedNetworkIndex = updatedNetworks.findIndex(
            (n) => n._id === selectedNetwork._id
          );
          updatedNetworks[updatedNetworkIndex].network.policies =
            resData.data.updatePolicies.policies;
          setNetworks(updatedNetworks);
          setUnsavedChanges(false);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const deletePolicyHandler = (index) => {
    const updatedPolicyList = [...policyList];
    const oldPolicyType = networkPolicies[index].name;
    for (let i = 0; i < updatedPolicyList.length; i++) {
      if (
        oldPolicyType != null &&
        updatedPolicyList[i].value === oldPolicyType
      ) {
        updatedPolicyList[i].used = false;
      }
    }
    const updatedPolicies = [...networkPolicies];
    updatedPolicies.splice(index, 1);
    setNetworkPolicies(updatedPolicies);
    setUnsavedChanges(true);
  };

  return (
    <Box>
      <List>
        <ListSubheader>Network Policies</ListSubheader>
      </List>
      {networkPolicies && networkPolicies.length > 0 ? (
        networkPolicies.map((policy, index) => {
          return (
            <ListItem key={index}>
              <Paper elevation={1} sx={{ padding: 3, width: "100%" }}>
                <Grid container spacing={2}>
                  <Grid container item xs={12} md={9} spacing={2}>
                    <Grid item xs={12} sm={7}>
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">
                          Policy
                        </InputLabel>
                        <Select
                          disabled={
                            user.uid !==
                            selectedNetwork.network.creator.firebaseId
                          }
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={policy.name ? policy.name : "choose_here"}
                          label="Age"
                          inputProps={{
                            readOnly:
                              user.uid !==
                              selectedNetwork.network.creator.firebaseId,
                          }}
                          onChange={(e) =>
                            updatedPolicyHandler("name", e.target.value, index)
                          }
                        >
                          {policyList.map((type) => {
                            return (
                              <MenuItem
                                key={type.value}
                                value={type.value}
                                disabled={
                                  type.value === "choose_here" || type.used
                                }
                              >
                                {type.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <FormControl sx={{ width: "100%" }}>
                        <TextField
                          disabled={
                            user.uid !==
                            selectedNetwork.network.creator.firebaseId
                          }
                          type="number"
                          id="value"
                          label="Value"
                          InputProps={{
                            readOnly:
                              user.uid !==
                              selectedNetwork.network.creator.firebaseId,
                          }}
                          onChange={(e) =>
                            updatedPolicyHandler("value", e.target.value, index)
                          }
                          value={policy.value ? policy.value : ""}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    {user.uid ===
                      selectedNetwork.network.creator.firebaseId && (
                      <Button
                        variant="contained"
                        onClick={() => deletePolicyHandler(index)}
                        color="error"
                        sx={{ width: "100%", marginTop: "8px" }}
                      >
                        <DeleteIcon />
                        Delete
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </ListItem>
          );
        })
      ) : (
        <Typography variant="body2" sx={{ textAlign: "center", margin: 2 }}>
          This network currently has no policies.
        </Typography>
      )}
      {user.uid === selectedNetwork.network.creator.firebaseId ? (
        <Grid container sx={{ justifyContent: "space-between" }}>
          {networkPolicies &&
            networkPolicies.length < policyTypes.length - 1 && (
              <Grid item>
                <Button
                  size="small"
                  variant="contained"
                  onClick={addPolicyHandler}
                  startIcon={<AddIcon />}
                  sx={{ float: "left", marginLeft: "10px" }}
                >
                  Add Policy
                </Button>
              </Grid>
            )}
          <Grid item>
            <Button
              disabled={!unsavedChanges}
              size="small"
              variant="contained"
              onClick={modalSavePolicyHandler}
              startIcon={<SaveIcon />}
              color="success"
            >
              Save Changes
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Alert sx={{ marginTop: 2 }} severity="info">
          Only Network Admins can edit policies
        </Alert>
      )}
    </Box>
  );
};
