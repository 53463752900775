import React from "react";
import { useSearchParams, Navigate } from "react-router-dom";

import ResetPassword from "./Actions/ResetPassword";
import VerifyEmail from "./Actions/VerifyEmail";

// eslint-disable-next-line react/prop-types
export default function EmailActions() {
  const [searchParams] = useSearchParams();
  const mode = searchParams.get("mode");
  const actionCode = searchParams.get("oobCode");

  switch (mode) {
    case "resetPassword":
      return <ResetPassword actionCode={actionCode} />;
    // case "recoverEmail":
    //  return <RecoverEmail actionCode={actionCode} />;
    case "verifyEmail":
      return <VerifyEmail actionCode={actionCode} />;
    default:
      return <Navigate replace to="/auth/login" />;
  }
}
