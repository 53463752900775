import React from "react";

import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Button,
} from "@mui/material";

const carList = (props) => (
  <List sx={{ maxWidth: "600px", margin: "auto" }}>
    {props.cars.map((car) => {
      return (
        <ListItem
          key={car._id}
          sx={{ border: "1px solid #ccc", borderRadius: "5px", mb: 1 }}
        >
          <ListItemText primary={car.name} secondary={car.reg} />
          <ListItemSecondaryAction>
            <Button
              variant="contained"
              color="error"
              // eslint-disable-next-line no-invalid-this
              onClick={props.onDelete.bind(this, car._id)}
            >
              Delete
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
      );
    })}
  </List>
);

export default carList;
