import React, { useState } from "react";

import { Box, Divider, Typography } from "@mui/material";

import { ViewStation } from "../components/Stations/ViewStation";
import { PastBookings } from "../components/Bookings/PastBookings";
import { FutureBookings } from "../components/Bookings/FutureBookings";

// eslint-disable-next-line react/prop-types
const BookingsPage = ({ user }) => {
  const [selectedCharger, setSelectedCharger] = useState(null);

  return (
    <React.Fragment>
      {selectedCharger && (
        <ViewStation
          viewStation={!!selectedCharger}
          latLng={{
            lat: selectedCharger.station.coordinates[0],
            lng: selectedCharger.station.coordinates[1],
          }}
          selectedCharger={selectedCharger}
          selectedStation={selectedCharger.station}
          modalCancelHandler={() => setSelectedCharger(null)}
          user={user}
          noStation={true}
        />
      )}
      <Box maxWidth={1000} margin={"auto"}>
        <Typography variant="h4" pt={10} ml={2}>
          Bookings
        </Typography>
        <Divider />
        <FutureBookings user={user} setSelectedCharger={setSelectedCharger} />
        <Divider />
        <PastBookings user={user} setSelectedCharger={setSelectedCharger} />
      </Box>
    </React.Fragment>
  );
};

export default BookingsPage;
