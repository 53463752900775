import React from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { TextField, Box, Button } from "@mui/material";
import validator from "validator";
import { useSnackbar } from "notistack";

// eslint-disable-next-line react/prop-types
function ResetPassword({ setReset }) {
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = React.useState("");
  const auth = getAuth();

  const triggerResetEmail = async () => {
    await sendPasswordResetEmail(auth, email)
      .then(() => {
        enqueueSnackbar("Password reset email sent!", {
          variant: "info",
        });
        setReset(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });
  };

  return (
    <Box mb={2}>
      <TextField
        id="email"
        label="Enter your email address"
        variant="outlined"
        value={email ? email : ""}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        sx={{ mt: 2, mb: 2 }}
      />
      <Button
        disabled={!validator.isEmail(email)}
        variant="contained"
        fullWidth
        onClick={triggerResetEmail}
      >
        Reset Password
      </Button>
    </Box>
  );
}

export default ResetPassword;
