import React from "react";

import styles from "./spinner.module.css";

const spinner = () => (
  <div className={styles.spinner}>
    <div className={styles.lds_dual_ring} />
  </div>
);

export default spinner;
