import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import AuthPage from "./pages/Auth";
import ProfilePage from "./pages/Profile";
import BookingsPage from "./pages/Bookings";
import ChargersPage from "./pages/Chargers";
import CarsPage from "./pages/Cars";
import NetworksPage from "./pages/Networks";
import MainNavigation from "./components/Navigation/Navigation";

import { SnackbarProvider } from "notistack";

import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBSE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_FIREBASE_MEASUREMENT_ID,
};

function App() {
  const [user, setUser] = React.useState(undefined);
  const app = initializeApp(firebaseConfig);
  getAnalytics(app);
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUser(user);
    } else {
      setUser(false);
    }
  });
  return (
    <Router>
      <SnackbarProvider>
        <MainNavigation user={user} />
        <main className="main-content">
          <Routes>
            <Route path="/auth/*" element={<AuthPage />} />
            {user !== false && (
              <Route path="/" element={<Navigate replace to="/chargers" />} />
            )}
            {user !== false && user !== undefined && (
              <>
                <Route
                  path="/auth/login"
                  element={<Navigate replace to="/chargers" />}
                />
                <Route
                  path="/auth/register"
                  element={<Navigate replace to="/chargers" />}
                />
              </>
            )}
            {(user === false || user === undefined) && (
              <Route path="/auth/*" element={<AuthPage />} />
            )}
            <Route path="/chargers" element={<ChargersPage user={user} />} />
            {user !== false && (
              <>
                <Route path="/profile" element={<ProfilePage user={user} />} />
                <Route
                  path="/bookings"
                  element={<BookingsPage user={user} />}
                />
                <Route path="/cars" element={<CarsPage user={user} />} />
                <Route
                  path="/networks"
                  element={<NetworksPage user={user} />}
                />
              </>
            )}
            {user === false && (
              <>
                <Route
                  path="/register"
                  element={<Navigate replace to="/auth/register" />}
                />
                <Route
                  path="*"
                  element={<Navigate replace to="/auth/login" />}
                />
              </>
            )}
          </Routes>
        </main>
      </SnackbarProvider>
    </Router>
  );
}

export default App;
