/* eslint-disable react/prop-types */
import React from "react";
import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  useJsApiLoader,
} from "@react-google-maps/api";
import Spinner from "../Spinner/Spinner";
import styles from "./map.module.css";
import AutocompletePlace from "./AutocompletePlace";

const containerStyle = {
  width: "100%",
  height: "100vh",
};

export default function DiffMap({
  autocomplete,
  enableClustering,
  stations,
  style,
  handleClick,
  LatLng,
  setLatLng,
  onViewDetail,
  zoom,
  handleZoomChanged,
  center,
  handleCenterChanged,
  name,
  setName,
  address,
  setAddress,
}) {
  const [libraries] = React.useState(["places"]);
  const [mapCenter, setMapCenter] = React.useState(
    LatLng
      ? LatLng
      : center
      ? center
      : {
          lat: 51.898,
          lng: -8.475,
        }
  );
  const [mapZoom, setMapZoom] = React.useState(zoom ? zoom : 14);
  const [map, setMap] = React.useState(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyA1xBYl1u-IP8vjXUXReJ1zHEB3sGQQMRw",
    libraries,
  });

  const setMapLatLng = (lat, lng) => {
    setMapZoom(18);
    setMapCenter({ lat, lng });
    setLatLng({ lat, lng });
  };

  // Marker & Cluster Icons
  const publicIcon = "/images/markers/public_icon.png";
  const privateIcon = "/images/markers/private_icon.png";
  const clusterOptions = {
    imagePath: "/images/clusterer/m",
  };

  return isLoaded ? (
    <>
      {autocomplete && (
        <>
          <AutocompletePlace
            setName={setName}
            setAddress={setAddress}
            setLatLng={setMapLatLng}
          />
          {name ? (
            <>
              <h3>Location Details</h3>
              <div className={styles.searchResult}>
                <h3>{name}</h3>
                <p>{address}</p>
              </div>
              <p>
                <i>Move the Marker to the stations exact location</i>
              </p>
            </>
          ) : (
            <br></br>
          )}
        </>
      )}
      <GoogleMap
        mapContainerStyle={style ? style : containerStyle}
        center={mapCenter}
        zoom={mapZoom}
        onLoad={(map) => {
          setMap(map);
        }}
        onZoomChanged={() => {
          if (map) handleZoomChanged(map.getZoom());
        }}
        onCenterChanged={() => {
          if (map) {
            handleCenterChanged(map.getCenter().toJSON());
          }
        }}
        clickableIcons={false}
        options={{
          disableDefaultUI: true,
          styles: [
            {
              elementType: "labels",
              featureType: "poi.business",
              stylers: [{ visibility: "off" }],
            },
          ],
        }}
        onClick={(e) => {
          handleClick && handleClick(e);
        }}
      >
        {LatLng && <Marker position={LatLng} />}
        {enableClustering ? (
          <MarkerClusterer
            options={clusterOptions}
            minimumClusterSize={3}
            clusterClass={styles.cluster}
          >
            {stations &&
              ((clusterer) =>
                stations.map((station, index) => {
                  return (
                    <Marker
                      key={station._id}
                      position={{
                        lat: station.coordinates[0],
                        lng: station.coordinates[1],
                      }}
                      onClick={onViewDetail.bind(this, station._id)}
                      icon={station.tomTomId ? publicIcon : privateIcon}
                      clusterer={clusterer}
                    ></Marker>
                  );
                }))}
          </MarkerClusterer>
        ) : (
          stations &&
          stations.map((station, index) => {
            return (
              <Marker
                key={station._id}
                position={{
                  lat: station.coordinates[0],
                  lng: station.coordinates[1],
                }}
                onClick={onViewDetail.bind(this, station._id)}
                icon={station.network ? privateIcon : publicIcon}
              ></Marker>
            );
          })
        )}
      </GoogleMap>
    </>
  ) : (
    <Spinner />
  );
}
