/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

import {
  Button,
  List,
  ListSubheader,
  Box,
  Divider,
  Typography,
} from "@mui/material";

import handleErrors from "../../utils/handleErrors";
import { config } from "../../Constants";
import Spinner from "../Spinner/Spinner";
import BookingList from "./BookingList";

const API_URL = config.api;

export const FutureBookings = ({ user, setSelectedCharger }) => {
  const [bookings, setBookings] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (user) fetchFutureBookings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const fetchFutureBookings = () => {
    setIsLoading(true);
    const requestBody = {
      query: `
            query {
              futureBookings {
                _id
                bookingStart
                bookingEnd
                createdAt
                cost
                charger {
                  _id
                  name
                  power
                  price
                  station {
                    _id
                    name
                    address
                    coordinates
                  }
                }
                car {
                  name
                }
              }
            }
          `,
    };
    user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
        },
      })
        .then((res) => {
          return handleErrors(res);
        })
        .then((resData) => {
          const bookings = {};
          resData.data.futureBookings.forEach((booking) => {
            const startDate = booking.bookingStart.split("T")[0];
            if (!bookings[startDate]) {
              const formattedDate = new Date(startDate).toLocaleDateString(
                "en-GB",
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              );
              bookings[startDate] = {
                bookings: [],
                formattedDate: formattedDate,
              };
            }
            bookings[startDate].bookings.push(booking);
          });
          // Sort bookings by time
          // eslint-disable-next-line guard-for-in
          for (const key in bookings) {
            bookings[key].bookings.sort((a, b) => {
              return new Date(a.bookingStart) - new Date(b.bookingStart);
            });
          }

          // Sort bookings by date
          const sortedBookingDates = {};
          Object.keys(bookings)
            .sort((a, b) => {
              return new Date(a) - new Date(b);
            })
            .forEach((key) => {
              sortedBookingDates[key] = bookings[key];
            });

          setBookings(sortedBookingDates);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    });
  };

  const deleteBookingHandler = (bookingId) => {
    setIsLoading(true);
    const requestBody = {
      query: `
          mutation CancelBooking($id: ID!) {
            cancelBooking(bookingId: $id)
          }
        `,
      variables: {
        id: bookingId,
      },
    };
    user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
        },
      })
        .then((res) => {
          return handleErrors(res);
        })
        .then((resData) => {
          const updatedBookingDates = bookings;
          // eslint-disable-next-line guard-for-in
          for (const key in updatedBookingDates) {
            const updatedBookings = updatedBookingDates[key].bookings.filter(
              (booking) => {
                return booking._id !== bookingId;
              }
            );
            if (updatedBookings.length === 0) {
              delete updatedBookingDates[key];
            } else {
              updatedBookingDates[key].bookings = updatedBookings;
            }
          }

          setIsLoading(false);
          setBookings(updatedBookingDates);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    });
  };

  return (
    <Box sx={{ pt: 2, pb: 2, pl: 2 }}>
      <Typography variant="h6" gutterBottom component="p">
        Upcoming Bookings
      </Typography>
      {isLoading ? (
        <Spinner />
      ) : bookings && Object.keys(bookings).length > 0 ? (
        <List>
          {Object.keys(bookings).map((date, index) => {
            return (
              <Box key={date} maxWidth={1000} margin={"10px"}>
                <ListSubheader sx={{ fontSize: "18px" }}>
                  {bookings[date].formattedDate}
                </ListSubheader>
                <BookingList
                  bookings={bookings[date].bookings}
                  onDelete={deleteBookingHandler}
                  setSelectedCharger={setSelectedCharger}
                />
                {index !== Object.keys(bookings).length - 1 && <Divider />}
              </Box>
            );
          })}
        </List>
      ) : (
        <Box sx={{ pt: 2, pb: 2, pl: 2 }}>
          <Typography variant="body1" gutterBottom component="p">
            You have no upcoming bookings.
          </Typography>
          <Button component={RouterLink} to="/chargers" variant="contained">
            Book a Charger
          </Button>
        </Box>
      )}
    </Box>
  );
};
