/* eslint-disable react/prop-types */
import React from "react";
import { Autocomplete } from "@react-google-maps/api";
class AutocompletePlace extends React.Component {
  constructor(props) {
    super(props);

    this.autocomplete = null;

    this.onLoad = this.onLoad.bind(this);
    this.onPlaceChanged = this.onPlaceChanged.bind(this);
  }

  onLoad(autocomplete) {
    this.autocomplete = autocomplete;
  }

  onPlaceChanged() {
    if (this.autocomplete !== null) {
      this.props.setName(this.autocomplete.getPlace().name);
      this.props.setAddress(this.autocomplete.getPlace().formatted_address);
      this.props.setLatLng(
        this.autocomplete.getPlace().geometry.location.lat(),
        this.autocomplete.getPlace().geometry.location.lng()
      );
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  }

  render() {
    return (
      <Autocomplete
        onLoad={this.onLoad}
        onPlaceChanged={this.onPlaceChanged}
        restrictions={{
          country: ["ie"],
        }}
      >
        <input
          type="text"
          placeholder="Where is the station located?"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `100%`,
            height: `48px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `16px`,
            outline: `none`,
            textOverflow: `ellipses`,
            zIndex: 1000,
          }}
        />
      </Autocomplete>
    );
  }
}

export default AutocompletePlace;
