/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Spinner from "../components/Spinner/Spinner";
import handleErrors from "../utils/handleErrors";
import { config } from "../Constants";
import { Divider, Box, Button, Typography } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { getAuth, deleteUser } from "firebase/auth";
import { Reauthenticate } from "../components/Profile/Reauthenticate";
import { useSnackbar } from "notistack";
const API_URL = config.api;

export default function Profile({ user }) {
  const { enqueueSnackbar } = useSnackbar();
  getAuth();
  const [reauth, setReauth] = useState(false);
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchProfile = () => {
      setIsLoading(true);
      const requestBody = {
        query: `
              query {
                user {
                  firstName
                  lastName
                  phoneNumber
                  email
                }
              }
            `,
      };
      user
        .getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          fetch(API_URL, {
            method: "POST",
            body: JSON.stringify(requestBody),
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + idToken,
            },
          })
            .then((res) => {
              return handleErrors(res);
            })
            .then((resData) => {
              setProfile(resData.data.user);
              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setIsLoading(false);
            });
        })
        .catch(function (error) {
          handleErrors(error.message);
        });
    };

    if (user) fetchProfile();
  }, [user]);

  const deleteAccountHandler = async () => {
    setIsLoading(true);
    const requestBody = {
      query: `
                mutation {
                  deleteUser
                }
              `,
    };

    user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      fetch(API_URL, {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
        },
      })
        .then((res) => {
          return handleErrors(res);
        })
        .then((resData) => {
          deleteUser(user)
            .then(() => {
              enqueueSnackbar("Account deleted!", { variant: "success" });
              setIsLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setIsLoading(false);
            });
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    });
  };

  return isLoading || !profile ? (
    <Spinner />
  ) : (
    <>
      {reauth && (
        <Reauthenticate
          user={user}
          setReauth={setReauth}
          modalCancelHandler={() => setReauth(false)}
          onReauth={deleteAccountHandler}
          titleText={"Delete Account"}
          buttonText={"Delete"}
        />
      )}
      <Box maxWidth={600} margin={"auto"} sx={{ pt: 10 }}>
        <Typography variant="h4" gutterBottom component="div">
          Account Details
        </Typography>
        <Divider />
        <Box display={"flex"} sx={{ alignItems: "center" }}>
          <Typography
            variant="body1"
            gutterBottom
            component="p"
            sx={{ pl: 2, mr: 2 }}
          >
            First Name:
          </Typography>
          <Typography variant="h6" gutterBottom component="p">
            {profile.firstName}
          </Typography>
        </Box>
        <Divider />
        <Box display={"flex"} sx={{ alignItems: "center" }}>
          <Typography
            variant="body1"
            gutterBottom
            component="p"
            sx={{ pl: 2, mr: 2 }}
          >
            Last Name:
          </Typography>
          <Typography variant="h6" gutterBottom component="p">
            {profile.lastName}
          </Typography>
        </Box>
        <Divider />
        <Box display={"flex"} sx={{ alignItems: "center" }}>
          <Typography
            variant="body1"
            gutterBottom
            component="p"
            sx={{ pl: 2, mr: 2 }}
          >
            Phone Number:
          </Typography>
          <Typography variant="h6" gutterBottom component="p">
            {profile.phoneNumber}
          </Typography>
        </Box>
        <Divider />
        <Box display={"flex"} sx={{ alignItems: "center" }}>
          <Typography
            variant="body1"
            gutterBottom
            component="p"
            sx={{ pl: 2, mr: 2 }}
          >
            Email:
          </Typography>
          <Typography variant="h6" gutterBottom component="p">
            {profile.email}
          </Typography>
        </Box>

        <Button
          sx={{ float: "right" }}
          variant="contained"
          color="error"
          onClick={() => setReauth(true)}
          startIcon={<DeleteForeverIcon />}
        >
          Delete Account
        </Button>
      </Box>
    </>
  );
}
